import React from 'react'

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from './general/users/Login';
import { Token } from './components/Include';

const UnProtectedView =()=> {
  return (
    <>
       
        <Routes>
        <Route  path="/" element={<Login /> } /> 

        <Route path="*" element={Token ===undefined? <Navigate to="/" /> :
         <Navigate to="/dashboard" />}/>


        </Routes>
        </>
  )
}

export default UnProtectedView