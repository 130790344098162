import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import axios from 'axios'
import { API_URL, config } from '../../components/Include';
import { ErrorModal, LoadingModal, closeModal } from '../../components/notify';

import { useParams } from 'react-router';

const UpdatePoll =()=> {

  let params =  useParams();
  const [poll, setPoll] = useState([] as any);

  const [errors, setErrors] = useState({
    question:'',
    option1:'',
    option2:'',
    option3:'',	
    successMessage:'',
    errorMessage:''
  });


  const handleReset =() =>{
    setPoll({
      question:'',
    option1:'',
    option2:'',
    option3:''
  })
  }

  

       const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	

        setPoll({...poll, [name]:value});
       setErrors({...errors, [name]:''});
     }



     const handleSwitch =( value:string)=>{
      value = value==='Active'?'Inactive':'Active'
      setPoll({...poll, status:value});
      }

     const handleSubmit =(event: FormEvent<HTMLButtonElement>, data:string)=>{
      event.preventDefault();
  
      let error = {...errors}; 
      let formIsValid = true;
  
  let msg ='This field is required';
  
  if(!poll.question){
      error.question =msg;
      formIsValid = false;
  } 

  if(!poll.option1){
    error.option1 =msg;
    formIsValid = false;
}
if(!poll.option2){
  error.option2 =msg;
  formIsValid = false;
}


if(!poll.option3){
  error.option3 =msg;
  formIsValid = false;
}

 
  

  if(!formIsValid){
    setErrors(error) 
    }
    
  if(formIsValid) {
  
    
      LoadingModal()
   const fd = new FormData();
      Object.entries(poll).forEach(([key, value]) => {
              fd.append(key, String(value));
          });
  
      
          let url = API_URL+'/poll/update/'+params.code;
    axios.post(url, fd, config)
    .then(response =>{
      if(response.data.status === 200){

setErrors({...errors, successMessage:response.data.message})
    

setTimeout(() => {
  if(data!==''){
    window.open(data, '_self')
  }
  
}, 3000);

  handleReset()

                } else{

setErrors({...errors, errorMessage:response.data.message})
                }   
            })
            .catch((error)=>{
             ErrorModal('oops', error.message)
            }).finally(()=>{
              closeModal()
            }) 
      }
  }
   
  const fetchContent = async()=>{
    let url = API_URL+'/poll/show/'+params.code
      await  axios.get(url,  config).then((result:any)=>{
        
        if(result.data.length!==0){
        setPoll(result.data.data)
        }
        })
        .catch((error)=>{
         //ErrorModal('oops', error.message)
        })
      }

      useEffect(()=>{  
        fetchContent()   
      },[]); 
  return (
    <>
   
    <div className="page-header d-sm-flex d-block"> 
               
               <h4 className="page-title">
                <i className="fa fa-cogs me-1"></i> Modify Poll</h4> 
                <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="/">Home</a></li> 
                <li className="breadcrumb-item"><a href="/general/polls">Polls</a></li>
                <li className="breadcrumb-item active" aria-current="page">Update</li> </ol> 
                </div> 



                {errors.errorMessage!==''?
				  <div className="alert alert-danger alert-dismissible fade show" role="alert">
          {errors.errorMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}
          

          {errors.successMessage!==''?
				  <div className="alert alert-success alert-dismissible fade show" role="alert">
          {errors.successMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}



               <div className="row row-sm">

               <div className="col-xl-12"> 
               <div className="card"> 
               <div className="card-header  "> 
               <div className="card-title text-primary">Modify Poll</div>

<div className="pull-right">
          <button type='button' onClick={(e)=>handleSubmit(e, '/general/poll/add')} className='btn btn-primary btn-sm'><i className='fa fa-save'></i> Save & New</button>&nbsp;
          <button type='button' onClick={(e)=>handleSubmit(e, '/general/polls')} className='btn btn-secondary btn-sm'><i className='fa fa-arrows-h'></i> Save & Return</button>&nbsp;
          <a href='/general/polls' className='btn btn-gray-dark btn-sm'> <i className='fa fa-arrow-left'></i> Return</a>
  </div>
                </div> 
                <div className="card-body"> 


               <div className="row"> 

          
               <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" name="question" value={poll.question} onChange={handleChange} placeholder="" className="form-control" id="question"  /> 
       
      

       <label htmlFor="question" className='fw-bold'>Question<span className='text-danger'>*</span> </label> 
       <span className="text-danger">{errors.question} </span>
       </div> 
       </div>

       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" name='option1' className="form-control" id="option1" placeholder="" value={poll.option1} onChange={handleChange} /> 
       
       <label htmlFor="option1" className='fw-bold'>Option 1<span className='text-danger'>*</span></label> <span className="text-danger">{errors.option1} </span>
       </div>  
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" name='option2' className="form-control" id="option2" placeholder="" value={poll.option2} onChange={handleChange} /> 
       
       <label htmlFor="option2" className='fw-bold'>Option 2<span className='text-danger'>*</span></label> <span className="text-danger">{errors.option2} </span>
       </div>  
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" name='option3' className="form-control" id="option3" placeholder="" value={poll.option3} onChange={handleChange} /> 
       
       <label htmlFor="option3" className='fw-bold'>Option 3<span className='text-danger'>*</span></label> <span className="text-danger">{errors.option3} </span>
       </div>  
       </div>

       <div className="col-md-12">
       <label htmlFor="formFile" className="form-label fw-bold">Is the Poll Active?</label> 

       
       <div onClick={()=>handleSwitch(poll.status)} className={`main-toggle main-toggle-secondary ${poll.status==='Active'?'on':'off'}`}> 
       <span></span> 
       </div>

       <br/>
       </div>

               </div> 
               
               
               </div> </div> 
               </div>

               </div>



    </>
  )
}

export default UpdatePoll