import React, { useEffect, useState } from 'react'
import { API_URL, config, API_IMAGES } from '../components/Include'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';

const Dashboard =()=> {

  const [stat, setStat] = useState({
published:0,
draft:0,
total:0,
category:0
  });
  const user  = useSelector((state:any) => state.rootReducer.user);


  const fetchContent = async()=>{
      let url = API_URL+'/news/statistics'
        await  axios.get(url,  config).then((result:any)=>{
          
          if(result.data.length!==0){
            setStat(result.data.data[0])
          }
          })
          .catch((error)=>{
          })
        
      }

      useEffect(()=>{
        fetchContent()
      }, [])

  return (
    <>
    
    <div className="mt-60"></div>
              
    <div className="page-header d-sm-flex d-block"> 
               
               <h4 className="page-title">
                <i className="fa fa-briefcase me-1"></i> Dashboard</h4> <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="#">Home</a></li> 
                <li className="breadcrumb-item active" aria-current="page">Programs</li> </ol> 
                </div> 


                <div className="row">
                  
                   <div className="col-md-12"> 
                   <div className="card"> 
                   <div className="card-body">
                     <div className="d-md-flex m-0"> 
                     <div className="me-4 my-auto">
                       <a href="#"> 
                       
                       <img className="avatar avatar-xl brround fit-content" src={API_IMAGES+'/staff/'+user.image_url} alt="profile" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =API_IMAGES+'/logo.jpg'}} /> </a> </div> <div className="my-md-auto my-4"> 
                       
                       <div> 
                        
                        <h4 className="media-heading fw-600">Hi, Welcome ! {user.staff_name} <span className="text-muted fs-12">( {user.group_code} )</span> </h4> <p className="text-muted">People write negatives things, cause they feel that's what sells. Good news to them, doesn't sell.</p></div> 
                        
                        <div className="progress progress-sm"> <div className="progress-bar progress-bar-striped progress-bar-animated bg-primary w-60"></div> 
                        </div> </div>
                        
                         <div className="ms-auto my-auto"> <a href="#" className="btn btn-pill btn-primary d-inline-flex"><i className="ri-edit-2-fill me-2 align-middle"></i>Edit Profile</a> </div> </div> </div> </div> </div> </div>


    <div className="row">


    
                <div className="col-xl-3 col-md-6 ">
                   <div className="card"> 
                   <div className="card-body"> 
                   <div className="float-end"> 
                   <span className="mini-stat-icon fs-20 bg-primary-transparent"><i className="fa fa-comments text-primary"></i></span> </div> 
                   <div className="dash3"> 
                   <h5 className="text-muted">Total News</h5> 
                   <h4 className="counter fw-semibold num-font">{stat.total}</h4> </div> </div> 
                   </div>
                    </div>

                    <div className="col-xl-3 col-md-6 ">
                   <div className="card"> 
                   <div className="card-body"> 
                   <div className="float-end"> 
                   <span className="mini-stat-icon fs-20 bg-danger-transparent"><i className="fa fa-eye-slash text-danger"></i></span> </div> 
                   <div className="dash3"> 
                   <h5 className="text-muted">Total Drafted</h5> 
                   <h4 className="counter fw-semibold num-font">{stat.draft}</h4> </div> </div> 
                   </div>
                    </div>


                    <div className="col-xl-3 col-md-6 ">
                   <div className="card"> 
                   <div className="card-body"> 
                   <div className="float-end"> 
                   <span className="mini-stat-icon fs-20 bg-success-transparent"><i className="fa fa-eye text-success "></i></span> </div> 
                   <div className="dash3"> 
                   <h5 className="text-muted">Total Published</h5> 
                   <h4 className="counter fw-semibold num-font">{stat.published}</h4> </div> </div> 
                   </div>
                    </div>


                    <div className="col-xl-3 col-md-6 ">
                   <div className="card"> 
                   <div className="card-body"> 
                   <div className="float-end"> 
                   <span className="mini-stat-icon fs-20 bg-secondary-transparent"><i className="fa fa-cubes text-secondary"></i></span> </div> 
                   <div className="dash3"> 
                   <h5 className="text-muted">Total Category</h5> 
                   <h4 className="counter fw-semibold num-font">{stat.category}</h4> </div> </div> 
                   </div>
                    </div>


                </div>


    </>
  )
}

export default Dashboard