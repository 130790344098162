
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { useEffect } from "react";
import UnProtectedView from "./UnProtectedView";
import Template from "./components/Template";
import { Token } from "./components/Include";

function App() {
  const loadScript = (src:any) => {
    return new Promise(function (resolve, reject) {
      var script = document.createElement('script')
      script.src = src
      script.addEventListener('load', function (e) {
        resolve(e)
      })
      script.addEventListener('error', function (e) {
        reject(e)
      })
      document.body.appendChild(script)
      document.body.removeChild(script)
    })
  }


  useEffect(() => {

   loadScript(`${process.env.PUBLIC_URL}/assets/js/sticky.js`)
    loadScript(`${process.env.PUBLIC_URL}/assets/js/sidebar.js`)
    loadScript(`${process.env.PUBLIC_URL}/assets/js/custom.js`)  

  }, [])
  return (
    <>

    <ReactNotifications />
             
              <Router>
            {Token !==undefined?   
            <Template />  :
          <UnProtectedView /> }
    
            </Router> 
    
            </>
  );
}

export default App;
