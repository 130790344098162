import { useState, ChangeEvent, FormEvent} from 'react'
import axios from 'axios'
import Select from 'react-select'
import { API_URL, config } from '../components/Include';
import { ErrorModal, LoadingModal, closeModal } from '../components/notify';


const AddStaff =()=> {

  const [image, setImage] = useState('');
  const [staff, setStaff] = useState({
    staff_name:'',
    display_name:'',	
    email_address:'',	
    about:'',
    group:'',
    password:'',
    isPassword:false
});

  const [errors, setErrors] = useState({
    staff_name:'',
    display_name:'',	
    email_address:'',	
    group:'',
    password:'',
    confirm_password:'',	
    successMessage:'',
    errorMessage:''
  });


  const handleReset =() =>{
    setStaff({
      staff_name:'',
    display_name:'',	
    email_address:'',	
    about:'',
    group:'',
    password:'',
    isPassword:false
  })
  }

  const handleSelect = (option:any, action:any)=>{
    setStaff({...staff, [action.name]:option.value});
      setErrors({...errors, [action.name]:''})
       }

       const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	

        setStaff({...staff, [name]:value});
       setErrors({...errors, [name]:''});
     }





     const handleSubmit =(event: FormEvent<HTMLButtonElement>, data:string)=>{
      event.preventDefault();
  
      let error = {...errors}; 
      let formIsValid = true;
  
  let msg ='This field is required';
  
  if(!staff.staff_name){
      error.staff_name =msg;
      formIsValid = false;
  } 

  if(!staff.display_name){
    error.display_name =msg;
    formIsValid = false;
}

let email = staff.email_address.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
if(!email){
  error.email_address =msg;
  formIsValid = false;
} 


if(!staff.group){
  error.group =msg;
  formIsValid = false;
}

 
  
  
  if(staff.password.length <8){
    error.password ='Please enter minimum of 8 characters';
    formIsValid = false;
  } 


  if(!formIsValid){
    setErrors(error) 
    }
    
  if(formIsValid) {
  
    
      LoadingModal()
   const fd = new FormData();
      Object.entries(staff).forEach(([key, value]) => {
              fd.append(key, String(value));
          });
  
          if(image){
            fd.append('image', image)
          }
     let url = API_URL+'/staff/create';
    axios.post(url, fd, config)
    .then(response =>{
      if(response.data.status === 200){

setErrors({...errors, successMessage:response.data.message})
    

setTimeout(() => {
  if(data!==''){
    window.open(data, '_self')
  }
  
}, 3000);

  handleReset()

                } else{

setErrors({...errors, errorMessage:response.data.message})
                }   
            })
            .catch((error)=>{
             ErrorModal('oops', error.message)
            }).finally(()=>{
              closeModal()
            }) 
      }
  }
   

  const  handleFile = function(fieldName:any){
    return function(newValue:any){                 
   readURL(newValue);
   }
  } 
  const readURL = (input:any)=>{

    let displayMessage = '';
    
    const PreviewImage = input.target.name;
    const inputElement = document.getElementById(PreviewImage) as HTMLInputElement
    const fileName = inputElement.value
    
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substring(idxDot, fileName.length).toLowerCase();
    
    if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = ' File uploaded is larger than maximum size Allow (500 kb).';
        inputElement.value = '';
     
        setImage('')
    
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            var reader = new FileReader();//Initialize FileReader.
            reader.onload = function (e:any) {


            //Check if input has files.
             setImage(input.target.files[0])
    
            }
            reader.readAsDataURL(input.target.files[0]);
            
        }
    }
    }else{
    displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 500 kb'
    inputElement.value = '';
    setImage('')
    }   
    
    if(displayMessage.length > 0){
   ErrorModal("", displayMessage)
    }
    }



    const ValidateExistence=()=>{
      if(staff.email_address!==''){
            
          let url = API_URL+'/staff/existence/'+staff.email_address;
          axios.get(url, config).then(response=>{
              if(response.data.status===200){
                  setErrors({...errors, email_address:staff.email_address + ' already exist'})
                  setStaff({...staff, email_address:''});
                  return response.data;
              }
        })
      }
  }

  return (
    <>
   
    <div className="page-header d-sm-flex d-block"> 
               
               <h4 className="page-title">
                <i className="fa fa-users me-1"></i> Add New Staff</h4> 
                <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="/">Home</a></li> 
                <li className="breadcrumb-item"><a href="/staff">Staff</a></li>
                <li className="breadcrumb-item active" aria-current="page">Add</li> </ol> 
                </div> 



                {errors.errorMessage!==''?
				  <div className="alert alert-danger alert-dismissible fade show" role="alert">
          {errors.errorMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}
          

          {errors.successMessage!==''?
				  <div className="alert alert-success alert-dismissible fade show" role="alert">
          {errors.successMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}



               <div className="row row-sm">

               <div className="col-xl-12"> 
               <div className="card"> 
               <div className="card-header  "> 
               <div className="card-title text-primary">Create Staff Account</div>

<div className="pull-right">
          <button type='button' onClick={(e)=>handleSubmit(e, '')} className='btn btn-primary btn-sm'><i className='fa fa-save'></i> Save & New</button>&nbsp;
          <button type='button' onClick={(e)=>handleSubmit(e, '/staff')} className='btn btn-secondary btn-sm'><i className='fa fa-arrows-h'></i> Save & Return</button>&nbsp;
          <a href='/staff' className='btn btn-gray-dark btn-sm'> <i className='fa fa-arrow-left'></i> Return</a>
  </div>
                </div> 
                <div className="card-body"> 


               <div className="row"> 

          
               <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" name="staff_name" value={staff.staff_name} onChange={handleChange} placeholder="" className="form-control" id="staff_name"  /> 
       
       <label htmlFor="staff_name" className='fw-bold'>Name<span className='text-danger'>*</span> </label> 
       <span className="text-danger">{errors.staff_name} </span>
       </div> 
       </div>

       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" name='display_name' className="form-control" id="display_name" placeholder="" value={staff.display_name} onChange={handleChange} /> 
       
       <label htmlFor="display_name" className='fw-bold'>Display Name (e.g Nickname)<span className='text-danger'>*</span></label> <span className="text-danger">{errors.display_name} </span>
       </div>  
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="email" className="form-control"   name='email_address' placeholder="" id="email_address"  value={staff.email_address} onBlur={ValidateExistence} autoComplete='off' role='presentation' onChange={handleChange} /> 
       
       <label htmlFor="email_address" className='fw-bold'>Email Address <span className='text-danger'>*</span></label> <span className="text-danger">{errors.email_address} </span>
       </div>  
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className="form-control" name='about' id="about" placeholder="" value={staff.about} onChange={handleChange} /> 
       
       <label htmlFor="about" className='fw-bold'>About The Staff</label> 
       </div> 
       </div>


       <div className="col-md-12">
        <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Select Group</label> 

       <Select  options={[
                    { value: 'Editor', label: 'Editor' },
                    { value: 'Reporter', label: 'Reporter'}
                                ]} 
                onChange={handleSelect}  
                name="group"
                 value={{value:staff.group, label:staff.group}} 
                 
                 />
<span className="text-danger">{errors.group} </span>
      </div>

       </div>



       <div className="col-md-12">

       <div className="mb-3">
         <label htmlFor="image" className="form-label fw-bold">Select Profile Picture</label> 
         <input className="form-control form-control-lg" type="file"  onChange={handleFile('image')} accept="image/*" name="image" id="image" /> 
         </div>

       </div>


       <div className="col-md-12">
       <label htmlFor="formFile" className="form-label fw-bold">Password</label> 


       <div className="input-group mb-3"> 
       
       <input aria-describedby="basic-addon1" aria-label="Username" className="form-control  form-control-lg" autoComplete='off' role='presentation' type={staff.isPassword?'text':'password'} name='password' id="password"  value={staff.password} onChange={handleChange} />
       <span className="input-group-text pointer" id="basic-addon1" onClick={()=>setStaff({...staff, isPassword:!staff.isPassword})}><i className={staff.isPassword?'fa fa-eye':'fa fa-eye-slash'}></i></span>  
       </div><span className="text-danger">{errors.password} </span>
 
       </div>


               </div> 
               
               
               </div> </div> 
               </div>

               </div>



    </>
  )
}

export default AddStaff