import { useState, ChangeEvent, useEffect, useCallback} from 'react'

import axios from 'axios';
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import { API_IMAGES, API_URL,  config } from '../components/Include';
import { usePaginated, DOTS } from '../components/usePagination';
import { CustomLoader } from '../components/notify';


const Staff =()=> {

  const [loading, setLoading] = useState(false);
  const [filterContent, setFilterContent] = useState([] as any);
  const [content, setContent] = useState([] as any);
  const [director, setDirector] = useState({
    isSN:false,
    isName:false,
    isPhoto:false,
    isDisplay:false,
    isEmail:false,
    isGroup:false,
    isStatus:false,
    isAction:true,
    isTotal:true,
   
  });
    const [items, setItems] = useState({
      filterText:''
    });

  const DeleteModal =(code:string)=>{

    let url = API_URL+'/staff/delete/'+code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =filterContent.filter((item:{code:string})=>item.code !==code)
            setContent(rs)
            setFilterContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }

  const handleClear =()=>{
    setItems({...items, filterText:''});
    setFilterContent(content)
}

  const handleResetColumn =()=>{

    setDirector({
      isSN:false,
      isName:false,
      isPhoto:false,
      isDisplay:false,
      isEmail:false,
      isGroup:false,
      isStatus:false,
      isAction:false,
      isTotal:false,})
  }

  const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setItems({...items, [name]:value});

    if(value!==''){
        const filteredItems = content.filter(
            (item:any) => item.staff_name.toLowerCase().includes(value.toLowerCase()) || 
            item.display_name.toLowerCase().includes(value.toLowerCase()) ||
            item.email_address.toLowerCase().includes(value.toLowerCase()) ||
            item.status.toLowerCase().includes(value.toLowerCase()) ||
            item.group_code.toLowerCase().includes(value.toLowerCase())
        );

        setFilterContent(filteredItems)
    }else{
        setFilterContent(content)
    } 

 }


  const TableData =()=>{

            
    const PaginationWrapper =(props:any)=>{
    
      const handleRowsPerPage = useCallback(
          (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
          [props.currentPage, props.onChangeRowsPerPage],
      );
      const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
      [props.currentPage, props.onChangeRowsPerPage],
      )
     
      function getNumberOfPages(rowCount:number, rowsPerPage:number) {
          return Math.ceil(rowCount / rowsPerPage);
        }
      const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
      const lastIndex = props.currentPage * props.rowsPerPage;
      const firstIndex = lastIndex - props.rowsPerPage + 1;
  
  
          const range =
          props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
  
          `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
  
      
     
      const Pagination = () => {
         
          const pageSize = props.rowsPerPage;
          const totalCount = content.length
          const siblingCount = 1
          const currentPage = props.currentPage
          
          const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
          
          if (currentPage === 0 ) {
            return null;
          }
          
          let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
          return (
          <nav className="" aria-label="Page navigation sample ">
            <ul  className="pagination pagination-sm"  >
              <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
               <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Prev</a>
              </li>
              {paginationRange&&paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                  return <li className="pagination-item dots" key={index}>
                   <a className="page-link pointer"  >&#8230;</a>
                  </li>
                }
                return (
                  <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                    
                  </li>
                );
              })}
          
          <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
          <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
              </li>
          
            </ul> </nav>
          );
          
              }
  
            

            
  
   return <div className="row mt-3">
                      
  <div className="col-md-5 ">
  <div className=" ml-3" >
  
      &nbsp; <select name="rowPerPage" id="" className=' h-40 ' onChange={handleRowsPerPage}>
          <option value="10"> 10</option>
          <option value="20"> 20</option>
          <option value="30"> 30</option>
          <option value="40"> 40</option>
          <option value="50"> 50</option>
          <option value={content.length}> All</option>
      </select> &nbsp;&nbsp;
       {range}  </div>
  </div>
    
  <div className="col-md-7  justify-content-end d-flex">
     <Pagination  />
      </div>
      
  </div>}
    
    
    
   
    
                const columns = [
    
                  {
                    name: 'SN',
                    cell: (row:any, index:number) => index+1, 
                  omit: director.isSN,
                    width:'60px'
                },
                     {
                        name: 'Photo',
                        cell: (row:any) => <div className="d-inline-block align-middle">
                        <img src={API_IMAGES+'/staff/'+row.image_url} alt="user image" className=" w-80 img-fluid img-thumbnail " onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =API_IMAGES+'/logo.jpg'}} />
                    </div>, 
                        sortable: true,
                        reorder:true,
                        omit: director.isPhoto,
                        width:'80px'
                    }, 
                    {
                      name: 'Name',
                      cell: (row:any) => row.staff_name, 
                      sortable: true,
                      reorder:true,
                      wordWrap:true,
                      omit: director.isName,
                      width:'200px'
                  },
                  {
                    name: 'Display Name',
                    cell: (row:any) => row.display_name, 
                    sortable: true,
                    reorder:true,
                    wordwrap:true,
                    omit: director.isDisplay,
                    width:'200px'
                },
                    {
                        name: 'Email',
                        selector: (row:any) =>row.email_address,
                        sortable: true,
                        reorder:true,
                        omit: director.isEmail,
                        wordwrap:true,
                    },
                    {
                      name: 'Group',
                      selector: (row:any) =>row.group_code,
                      sortable: true,
                      omit: director.isGroup,
                      reorder:true,
                      width:'150px'
                  },
                  {
                    name: 'Total',
                    selector: (row:any) => row.total_item,
                    sortable: true,
                    reorder:true,
                    omit: director.isTotal,
                    width:'100px'
                },
                  {
                    name: 'Status',
                    cell: (row:any) => <span className={`badge  ${row.status==='Active'?'badge-secondary':'badge-primary'} me-1`}>{row.status}</span>,
                    omit: director.isStatus,
                  width:'100px'
                },
                    {
                        name: 'Action',
                        cell: (row:any, index:any, column:any, id:any) =><div >  
                           
                           <a href={"/staff/edit/"+row.code}  className="btn btn-info-transparent btn-sm"   ><i className='fa fa-pencil'></i> Modify</a>
   &nbsp;
  
                <button className="btn btn-danger-transparent btn-sm" data-bs-toggle="tooltip" title="" data-bs-original-title="Delete" onClick={()=>DeleteModal(row.code)}><i className='fa fa-trash'></i> Delete</button>
                       
                       </div>,
                            left:true,
                            omit: director.isAction,
                            width:'200px'
                    },
                ];
                
            
            
            
                return <>
                
    
                <DataTable
                columns={columns}
                data={filterContent}
                highlightOnHover
                pagination
                paginationComponent={PaginationWrapper}
                selectableRowsHighlight
                striped
                responsive
                fixedHeader
                progressPending={loading}
                progressComponent={<CustomLoader />}
            />
            </>
            }
    


    const fetchContent = async()=>{
      setLoading(true)
      let url = API_URL+'/staff/display/all'
        await  axios.get(url,  config).then((result:any)=>{
          if(Array.isArray(result.data.data)){
          setContent(result.data.data)
          setFilterContent(result.data.data)
          }
          })
          .catch((error)=>{
            //ErrorModal('oops', error.message)
          }).finally(()=>{
            setLoading(false)
          })
        }

        useEffect(()=>{  
          fetchContent()   
        },[]); 
  return (
    <>
    

              
    <div className="page-header d-sm-flex d-block"> 
               
               <h4 className="page-title">
                <i className="fa fa-users me-1"></i> Staff</h4> 
                <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="/">Home</a></li> 
                <li className="breadcrumb-item active" aria-current="page">Staff</li> </ol> 
                </div> 


               <div className="row row-sm">

               <div className="col-xl-12"> 
               <div className="card"> 
               <div className="card-header  d-flex justify-content-between"> 
               <div className="card-title">All Staff</div>

                <a href='/staff/add'   className='btn btn-primary'> <i className='fa fa-plus'></i> Add New Staff</a>
          
                </div> 
                <div className="card-body"> 
               
                <div className="row">

<div className=" col-md-8 ">

  <div className="btn-group mb-3 ">
                   
  <div className=""> 

                    <button type='button' className='btn btn-secondary' data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" ><i className="fa fa-filter text-gray-500 fs-18"></i> Column Visibility <i className='fa fa-arrow-down'></i></button>
  
   <ul className="dropdown-menu top " role="menu" > 
   
   <li><a  className={director.isSN?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isSN:!director.isAction})}>SN</a></li> 


   <li><a className={director.isPhoto?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isPhoto:!director.isPhoto})}>Photo</a></li> 

   <li><a className={director.isName?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isName:!director.isName})}>Name</a></li> 


   <li><a className={director.isDisplay?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isDisplay:!director.isDisplay})}>Display Name</a></li> 

   <li><a className={director.isEmail?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isEmail:!director.isEmail})}>Email Address</a></li> 

   <li><a className={director.isGroup?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isGroup:!director.isGroup})}>Staff Group</a></li>

   <li><a className={director.isTotal?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isTotal:!director.isTotal})}>Total News</a></li> 


   <li><a className={director.isStatus?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isStatus:!director.isStatus})}>Status</a></li> 

   <li> <a className={director.isAction?"bg-secondary text-white":""} href="#!" onClick={()=>setDirector({...director, isAction:!director.isAction})}>Action</a></li> 
   <div className="dropdown-divider"></div> 
   <li><a href="#" onClick={handleResetColumn}>Reset Column</a></li> 
   </ul>
  
  
  </div>

                  </div>
                  </div>

    <div className=" col-md-4 ">

    <div className="input-group mb-3"> 
       
    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={items.filterText} className="form-control" placeholder="Type to search" />
       <span onClick={handleClear} className="input-group-text bg-default pointer" id="basic-addon1"><i className="fa fa-close"></i></span>  
       </div>

       
      

    </div>
</div>


               <TableData />

            </div> </div> 
               </div>

               </div>



    </>
  )
}

export default Staff