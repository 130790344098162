import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'

import axios from 'axios'
import { ErrorModal, LoadingModal, closeModal } from '../../components/notify';
import { API_URL, config, API_IMAGES } from '../../components/Include';

const ImagesLogo =(props:any)=> {
    const [content, setContent] = useState(props.content);

    const [image, setImage] = useState({
      favicon:'',
      logo_white:'',
      logo_dark:'',
      long_logo_white:'',
      long_logo_dark:'',


      favicon_path:'',
      logo_white_path:'',
      logo_dark_path:'',
      long_logo_white_path:'',
      long_logo_dark_path:''

    });
    const [errors, setErrors] = useState({
      favicon:'',
        successMessage:'',
        errorMessage:''
      });




const  handleFile = function(fieldName:any){
  return function(newValue:any){                 
 readURL(newValue);
 }
} 
const readURL = (input:any)=>{

  let displayMessage = '';
  
  const PreviewImage = input.target.name;
  const inputElement = document.getElementById(PreviewImage) as HTMLInputElement
  const fileName = inputElement.value
  
  var idxDot = fileName.lastIndexOf(".") + 1;
  var extFile = fileName.substring(idxDot, fileName.length).toLowerCase();
  
  if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
      //TO DO    
      
       var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
  if (FileSize > 3) {
      displayMessage = ' File uploaded is larger than maximum size Allow (3 mb).';
      inputElement.value = '';
   
   setImage({...image, [PreviewImage]:'', [PreviewImage+'_path']:''})
 
  } else {
      if (input.target.files && input.target.files[0]) { //Check if input has files.
          var reader = new FileReader();//Initialize FileReader.
          reader.onload = function (e:any) {
           
          //Check if input has files.
           setImage({...image, [PreviewImage]:input.target.files[0], [PreviewImage+'_path']:e.target.result})
          }
          reader.readAsDataURL(input.target.files[0]);
      }
  }
  }else{
  displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 3 mb'
  inputElement.value = '';
  setImage({...image, [PreviewImage]:'', [PreviewImage+'_path']:''})
 
  }   
  
  if(displayMessage.length > 0){
 ErrorModal("", displayMessage)
  }
  }


     
  const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!content.favicon){
    error.favicon =msg;
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}


if(formIsValid) {

    LoadingModal()
 const fd = new FormData();
    Object.entries(content).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

        if(image.favicon){
          fd.append('favicon_image', image.favicon)
        }

        if(image.logo_white){
          fd.append('logo_white_image', image.logo_white)
        }

        if(image.logo_dark){
          fd.append('logo_dark_image', image.logo_dark)
        }

        if(image.long_logo_white){
          fd.append('long_logo_white_image', image.long_logo_white)
        }

        if(image.long_logo_dark){
          fd.append('long_logo_dark_image', image.long_logo_dark)
        }

   let url = API_URL+'/general/basic/update';
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){

setErrors({...errors, successMessage:response.data.message})

              } else{

setErrors({...errors, errorMessage:response.data.message})
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{
            closeModal()
          }) 
    }
}

  return (
    <>

<div className="card-block p-3">
{errors.errorMessage!==''?
<div className="alert alert-danger alert-dismissible fade show" role="alert">
{errors.errorMessage}
<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>:''}


{errors.successMessage!==''?
<div className="alert alert-success alert-dismissible fade show" role="alert">
{errors.successMessage}
<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>:''}

<div className="row">

<div className="col-md-9">

<div className="mb-3">
  <label htmlFor="file" className="form-label fw-bold">Favicon Icon</label> 
  <input className="form-control" type="file"  onChange={handleFile('favicon')} accept="image/*" name="favicon" id="favicon" /> 

  </div>

</div>


<div className="col-md-3">

<div className="mb-3">
<img className=" w-100" src={image.favicon_path?image.favicon_path:API_IMAGES+'/general/'+content.favicon} alt="favicon"  /> 


  </div>

</div>



<div className="col-md-9">

<div className="mb-3">
  <label htmlFor="file" className="form-label fw-bold">Logo</label> 
  <input className="form-control" type="file"  onChange={handleFile('logo_white')} accept="image/*" name="logo_white" id="logo_white" /> 

  </div>

</div>

<div className="col-md-3">

<div className="mb-3">
<img className=" w-100" src={image.logo_white_path?image.logo_white_path:API_IMAGES+'/general/'+content.logo_white} alt="logo"  /> 


  </div>

</div>



<div className="col-md-9">

<div className="mb-3">
  <label htmlFor="file" className="form-label fw-bold">Dark Logo</label> 
  <input className="form-control" type="file"  onChange={handleFile('logo_dark')} accept="image/*" name="logo_dark" id="logo_dark" /> 

  </div>

</div>

<div className="col-md-3">

<div className="mb-3"><br/>
<img className=" w-100" src={image.logo_dark_path?image.logo_dark_path:API_IMAGES+'/general/'+content.logo_dark} alt="Dark Logo"  /> 


  </div>

</div>


<div className="col-md-9">

<div className="mb-3">
  <label htmlFor="file" className="form-label fw-bold">Long Logo</label> 
  <input className="form-control" type="file"  onChange={handleFile('long_logo_white')} accept="image/*" name="long_logo_white" id="long_logo_white" /> 

  </div>

</div>
<div className="col-md-3">

<div className="mb-3"><br/>
<img className=" w-100" src={image.long_logo_white_path?image.long_logo_white_path:API_IMAGES+'/general/'+content.long_logo_white} alt="long logo"  /> 


  </div>

</div>

<div className="col-md-9">

<div className="mb-3">
  <label htmlFor="file" className="form-label fw-bold">Dark Long Logo</label> 
  <input className="form-control" type="file"  onChange={handleFile('long_logo_dark')} accept="image/*" name="long_logo_dark" id="long_logo_dark" /> 

  </div>

</div>
<div className="col-md-3">

<div className="mb-3"><br/>
<img className=" w-100" src={image.long_logo_dark_path?image.long_logo_dark_path: API_IMAGES+'/general/'+content.long_logo_dark} alt="dark long logo"  /> 


  </div>

</div>


</div>


       <div className="card-footer">
<div className="mb-4">
          <button type='button' onClick={handleSubmit} className='btn btn-success btn-block '><i className='fa fa-image'></i> Upload Images</button>&nbsp;
  </div>

</div>
</div>
       </>
  )
}

export default ImagesLogo