
import Cookies from 'js-cookie'

export const API_URL = process.env.REACT_APP_API_URL; 
export const SITE_URL =process.env.REACT_APP_SITE_URL;  
export const API_IMAGES = process.env.REACT_APP_API_IMAGES;
export const CLOUD_IMAGES = process.env.REACT_APP_CLOUD_IMAGE_URL;
export const TINYMCE_KEY = process.env.REACT_APP_TINY_MCE_API_KEY;


export const StaffCode = Cookies.get('thrucd')?Cookies.get('thrucd'):undefined
export const Token = Cookies.get('thrtk')?Cookies.get('thrtk'):undefined

export const config = {
    headers: { 'content-type': 'multipart/form-data',
    'Authorization': `Bearer ${Token}`}
} 