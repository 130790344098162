import Topbar from './Topbar'
import Sidebar from './Sidebar'
import Footer from './Footer'
import ProtectedView from '../ProtectedView'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import axios from 'axios';
import { API_URL, config } from './Include';

const Template =()=> {
  const dispatch = useDispatch()

const fetchContent = async()=>{
  let url = API_URL+'/staff/news/category'
  const {data} = await axios.get(url,  config)
  dispatch({
    type:'SET_RECORDS',
    name:'category',
    data:data.category
})
dispatch({
  type:'SET_RECORDS',
  name:'staff',
  data:data.staff
})
    }


useEffect(()=>{
  fetchContent()
},[])
  return (
    <>
  
<div className="horizontalMenucontainer">
    
  
          {/* <div id="global-loader" >
            <img src="images/loader.svg" alt="loader" />
            </div>   */}
           
           <div className="page"> 
               <div className="page-main"> 
               
               <div> 
                   <Topbar />
                   
                   
                
                   
                   <Sidebar />
                   
                  

               
               <div className="main-content app-content"> 
               
               
               <div className="main-container container-fluid"> 
               <div className="mt-60"></div>



               <ProtectedView />
                
                </div> 
               
               
               
               
               
                     
                      
                    
                       
                        
                         </div> 
                         
                          <Footer />
                          
                           </div> </div> 
           
           <a href="#top" id="back-to-top"><i className="fa fa-angle-up "></i></a>  
  
  </div>
  </div>

    </>
  )
}

export default Template