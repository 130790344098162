import { useState, ChangeEvent, useEffect, useCallback} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import { API_URL, config } from '../components/Include';
import { usePaginated, DOTS } from '../components/usePagination';
import { CustomLoader } from '../components/notify';


const Subscribers =()=> {

  const [loading, setLoading] = useState(false);
  const [filterContent, setFilterContent] = useState([] as any);
  const [content, setContent] = useState([] as any);
let initial ={
  title:'',
  filterText:''
}

  const [items, setItems] = useState(initial);

  const [errors, setErrors] = useState({
    title:'',
    successMessage:'',
    errorMessage:''
  });




     const handleClear =()=>{
      setItems({...items, filterText:''});
      setFilterContent(content)
  }

   const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	
      setItems({...items, [name]:value});

      if(value!==''){
          const filteredItems = content.filter(
              (item:any) => item.email.toLowerCase().includes(value.toLowerCase()) || 
              item.fullname.toLowerCase().includes(value.toLowerCase()) 
          );

          setFilterContent(filteredItems)
      }else{
          setFilterContent(content)
      } 

   }

const fetchContent = async()=>{
  setLoading(true)
  let url = API_URL+'/general/subscriber/display/all'
    await  axios.get(url,  config).then((result:any)=>{
      if(Array.isArray(result.data.data)){
      setContent(result.data.data)
      setFilterContent(result.data.data)
      }
      })
      .catch((error)=>{
       //ErrorModal('oops', error.message)
      }).finally(()=>{
        setLoading(false)
      })
    }

   

  const DeleteModal =(code:string)=>{

    let url = API_URL+'/general/subscriber/delete/'+code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =filterContent.filter((item:{code:string})=>item.code !==code)
            setContent(rs)
            setFilterContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }


  const TableData =()=>{

            
    const PaginationWrapper =(props:any)=>{
    
        const handleRowsPerPage = useCallback(
            (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
            [props.currentPage, props.onChangeRowsPerPage],
        );
        const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
        [props.currentPage, props.onChangeRowsPerPage],
        )
       
        function getNumberOfPages(rowCount:number, rowsPerPage:number) {
            return Math.ceil(rowCount / rowsPerPage);
          }
        const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
        const lastIndex = props.currentPage * props.rowsPerPage;
        const firstIndex = lastIndex - props.rowsPerPage + 1;
    
    
            const range =
            props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
    
            `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
    
        
       
        const Pagination = () => {
           
            const pageSize = props.rowsPerPage;
            const totalCount = content.length
            const siblingCount = 1
            const currentPage = props.currentPage
            
            const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
            
            if (currentPage === 0 ) {
              return null;
            }
            
            let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
            return (
            <nav className="" aria-label="Page navigation sample ">
              <ul  className="pagination pagination-sm"  >
                <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                 <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Prev</a>
                </li>
                {paginationRange&&paginationRange.map((pageNumber, index) => {
                  if (pageNumber === DOTS) {
                    return <li className="pagination-item dots" key={index}>
                     <a className="page-link pointer"  >&#8230;</a>
                    </li>
                  }
                  return (
                    <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                      <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                      
                    </li>
                  );
                })}
            
            <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
            <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                </li>
            
              </ul> </nav>
            );
            
                }
    
              
  
              
    
     return <div className="row mt-3">
                        
    <div className="col-md-5 ">
    <div className=" ml-3" >
    
        &nbsp; <select name="rowPerPage" id="" className=' h-40 ' onChange={handleRowsPerPage}>
            <option value="10"> 10</option>
            <option value="20"> 20</option>
            <option value="30"> 30</option>
            <option value="40"> 40</option>
            <option value="50"> 50</option>
            <option value={content.length}> All</option>
        </select> &nbsp;&nbsp;
         {range}  </div>
    </div>
      
    <div className="col-md-7  justify-content-end d-flex">
       <Pagination  />
        </div>
        
    </div>}
    
    
                const columns = [
    
                    {
                        name: 'SN',
                        cell: (row:any, index:number) => index+1, 
               
                        width:'60px'
                    },
                    {
                      name: 'Name',
                      cell: (row:any) => row.fullname, 
                      sortable: true,
                      reorder:true
                  },
                    {
                      name: 'Email Address',
                      selector: (row:any) => row.email,
                      sortable: true,
                      reorder:true
                  },
                  {
                    name: 'Date Added',
                    selector: (row:any) => row.created_at,
                    sortable: true,
                    reorder:true
                },
                    {
                        name: 'Action',
                        cell: (row:any, index:any, column:any, id:any) => <div >  
                           
                 <button className="btn btn-danger-transparent btn-sm" data-bs-toggle="tooltip" title="" data-bs-original-title="Delete" onClick={()=>DeleteModal(row.code)}><i className='fa fa-trash'></i> Delete</button>
                        
                        </div>,
                            left:true,
                    }
                ];
                
            
                return <>
                
    
                <DataTable
                columns={columns}
                data={filterContent}
                highlightOnHover
                pagination
                paginationComponent={PaginationWrapper}
                selectableRowsHighlight
                striped
                responsive
                fixedHeader
                progressPending={loading}
                progressComponent={<CustomLoader />}
            />
            </>
            }
    
            
    useEffect(()=>{  
      fetchContent()   
    },[]); 
  return (
    <>


              
    <div className="page-header d-sm-flex d-block"> 
               
               <h4 className="page-title">
                <i className="fa fa-cogs me-1"></i> Subscribers</h4> 
                <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="/dashboard">Home</a></li> 
                <li className="breadcrumb-item active" aria-current="page">Subscribers</li> </ol> 
                </div> 


                {errors.errorMessage!==''?
				  <div className="alert alert-danger alert-dismissible fade show" role="alert">
          {errors.errorMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}
          

          {errors.successMessage!==''?
				  <div className="alert alert-success alert-dismissible fade show" role="alert">
          {errors.successMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}

               <div className="row row-sm">

               <div className="col-xl-12"> 
               <div className="card"> 
               <div className="card-header  d-flex justify-content-between"> 
               <div className="card-title">All Subscribers</div>

                </div> 
                <div className="card-body"> 



                <div className="row">

<div className=" col-md-8 ">

  <div className="btn-group mb-3 ">
                   
  <button type='button'  data-bs-target="#addexport" data-bs-toggle="modal"  className='btn btn-dark'> <i className='fa fa-download'></i> Export</button>


                  </div>
                  </div>

    <div className=" col-md-4 ">

    <div className="input-group mb-3"> 
       
    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={items.filterText} className="form-control" placeholder="Type to search" />
       <span onClick={handleClear} className="input-group-text bg-default pointer" id="basic-addon1"><i className="fa fa-close"></i></span>  
       </div>
    </div>
</div>


               <TableData />

               </div> </div> 
               </div>

               </div>



    </>
  )
}

export default Subscribers