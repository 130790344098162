import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'
import axios from 'axios'
import Setup from './Setup';
import Social from './Social';
import ImagesLogo from './ImagesLogo';
import { LoadingModal, closeModal } from '../../components/notify';
import { API_URL, config } from '../../components/Include';

const BasicInfo =()=> {
    const [activeItem, setActiveItem] = useState(1);
    const [content, setContent] = useState([] as any);
    const steps = [
        {title: 'Site Settings'}, 
        {title: 'Social Handles'},
        {title: 'Images & Logo'}
    ]

    const [errors, setErrors] = useState({
        title:'',
        successMessage:'',
        errorMessage:''
      });


      const fetchContent = async()=>{
       LoadingModal()
        let url = API_URL+'/general/basic/display/all'
          await  axios.get(url,  config).then((result:any)=>{
            if(Array.isArray(result.data.data)){
            setContent(result.data.data[0])
            }
            })
            .catch((error)=>{
             //ErrorModal('oops', error.message)
            }).finally(()=>{
              closeModal()
            })
          }

          useEffect(()=>{  
            fetchContent()   
          },[]); 

  return (<>
    
    <div className="page-header d-sm-flex d-block"> 
               
               <h4 className="page-title">
                <i className="fa fa-cogs me-1"></i> Basic Information</h4> 
                <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="/dashboard">Home</a></li> 
                <li className="breadcrumb-item active" aria-current="page">General</li> </ol> 
                </div> 



     <div  className="row">
     <div className=" col-xl-3 col-lg-4 col-md-12 col-sm-12"> 
        <div className="card vh-100">
            <div className="card-header ">


            <h4 className='text-primary text-center'> 
              <i className="fa fa-globe"></i> Site Details</h4>
              
            </div>
            <div className="card-block">


<div className="p-3 h-600">

   <ul className="nav nav-pills main-nav-column"> 
   
    {steps.map((list:any, id:number)=>
   <li className="nav-item " key={id}><a className= {activeItem===(id+1)?'nav-link thumb active':'nav-link thumb'} href="#" onClick={()=>setActiveItem(id+1)}> {list.title} </a></li> 
   )}

    </ul> 
   </div> 
   
   
   
   </div>

        </div>


    </div>
    <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">


        <div className="card ">
            <div className="card-header ">


            <h4 className='text-primary text-center'> 
              <i className="fa fa-globe"></i> {steps[activeItem-1].title}</h4>
              
            </div>
         {content.length!==0?  <div className=" p-3">

                   {activeItem===1?
                                
                            <Setup content={content} />
                                    :activeItem===2?
                                                        
                                    <Social  content={content} />
                                                    
                            :
                            <ImagesLogo content={content} />
                            }
         </div>:[]}

        </div>

    </div>
</div>
</>
  )
}

export default BasicInfo