import { useState, ChangeEvent, useEffect, useCallback} from 'react'

import UsePagination from '../components/pageNumber';
import { useSelector } from 'react-redux'
import Select from 'react-select'
import axios from 'axios';
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import { API_URL, API_IMAGES, config, SITE_URL } from '../components/Include';
import { usePaginated, DOTS } from '../components/usePagination';
import { closeModal, CustomLoader, LoadingModal } from '../components/notify';


const News =()=> {

  const staff  = useSelector((state:any) => state.rootReducer.staff);
  const category  = useSelector((state:any) => state.rootReducer.category);
  const [filterContent, setFilterContent] = useState([] as any);
  const [content, setContent] = useState([] as any);
  const [search, setSearch] = useState({
    filterText:'',
    category:{label:'', value:''},
    staff:{label:'', value:''},
    author:{label:'', value:''},
    status:{label:'', value:''},
   title:'',
   date_posted:''

  });
  const [perPage, setPerPage] = useState(30);
  let [page, setPage] = useState(1);
	const [totalPost, setTotalPost] = useState([] as any);

  const handleReset =()=>{
    setSearch({
      filterText:'',
    category:{label:'', value:''},
    staff:{label:'', value:''},
    author:{label:'', value:''},
    status:{label:'', value:''},
   title:'',
   date_posted:''
    })
    fetchContent(0)
  }
  const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setSearch({...search, [name]:value});

    if(value!==''){
        const filteredItems = content.filter(
            (item:any) => item.title.toLowerCase().includes(value.toLowerCase()) || 
            item.category_name.toLowerCase().includes(value.toLowerCase()) ||
            item.status.toLowerCase().includes(value.toLowerCase()) ||
            item.staff_name.toLowerCase().includes(value.toLowerCase())
        );

        setFilterContent(filteredItems)
    }else{
        setFilterContent(content)
    } 

 }
  const [director, setDirector] = useState({
    isSN:true,
    isTitle:true,
    isPhoto:true,
    isCategory:true,
    isAction:true,
    isView:true,
    isDate:false,
    isStatus:false,
    isAuthor:false,
  });

  const handleResetColumn =()=>{

    setDirector({
      isSN:true,
      isTitle:true,
      isPhoto:true,
      isCategory:true,
      isAction:true,
      isView:true,
      isDate:false,
      isStatus:false,
      isAuthor:false,
  })
  }
  const handleClear =()=>{
    setSearch({...search, filterText:''});
    setFilterContent(content)
}


const nextPage = (num:number)=>{
	setPage(num)
	fetchContent(num)
  }

const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
  let {name, value} = event.target;	

  setSearch({...search, [name]:value});
}

  const handleSelect = (option:any, action:any)=>{
    setSearch({...search, [action.name]:option});

       }

  const DeleteModal =(code:string)=>{

    let url = API_URL+'/news/delete/'+code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =filterContent.filter((item:{code:string})=>item.code !==code)
            setContent(rs)
            setFilterContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }
    

const fetchContent = async(offset:number)=>{
  LoadingModal()
let url = API_URL+'/news/display/all'
const fd = new FormData();
fd.append('category', search.category.value)
fd.append('author', search.author.value)
fd.append('title', search.title)
fd.append('staff', search.staff.value)
fd.append('status', search.status.value)
fd.append('date_post', search.date_posted)
fd.append('offset', String(offset*30))


  await  axios.post(url, fd, config).then((result:any)=>{

    if(Array.isArray(result.data.data)){
    setContent(result.data.data)
    setTotalPost(result.data.total)
    setFilterContent(result.data.data)
    }
    })
    .catch((error)=>{
     // ErrorModal('oops', error.message)
    }).finally(()=>{
      closeModal()
    })
  }
  


  const Pagination = () => {
         
    const pageSize = perPage;
    const totalCount = totalPost;
    const siblingCount = 1
    const currentPage = page
    
      const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
    
      if (currentPage === 0 ) {
      return null;
      }
      
      let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
      return (

        <nav className="" aria-label="Page navigation sample ">
              <ul  className="pagination pagination-sm"  >

                
                <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                 <a className="page-link pointer"  onClick={() =>nextPage(page - 1)}>Previous</a>
                </li>



                {paginationRange&&paginationRange.map((pageNumber, index) => {
                  if (pageNumber === DOTS) {
                    return <li className="pagination-item dots" key={index}>
                     <a className="page-link pointer"  >&#8230;</a>
                    </li>
                  }
                  return (
                    <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                      <a className="page-link pointer"  onClick={() =>nextPage(pageNumber)} >{pageNumber}</a>
                      
                    </li>
                  );
                })}
            
            <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
            <a className="page-link pointer"  onClick={() =>nextPage(page + 1)} aria-label="Next">Next</a>
                </li>

            
              </ul> </nav>
      );
    
        }


  useEffect(()=>{  
    fetchContent(0)   
  },[]);


  return (
    <>
    

              
    <div className="page-header d-sm-flex d-block"> 
               
               <h4 className="page-title">
                <i className="fa fa-comment me-1"></i> News</h4> 
                <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="/">Home</a></li> 
                <li className="breadcrumb-item active" aria-current="page">News</li> </ol> 
                </div> 



                <div className="row row-sm">

<div className="col-xl-12"> 
<div className="card"> 
<div className="card-header  d-flex justify-content-between"> 
<div className="card-title text-primary">Search News</div>


 </div> 
 <div className="card-body"> 

<div className="row">

<div className="col-md-4">
       <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Search By Category</label> 
       <Select  options={
				category&&category.map((node:any, id:number)=>{
					return {value:node.code, key:id, label:node.category_name}
				})} 
                onChange={handleSelect}  
                name="category"
                 value={search.category} 
                 
                 />

</div>
       </div>


       <div className="col-md-4">
       <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Search By Author</label> 
       <Select  options={
				staff&&staff.map((node:any, id:number)=>{
					return {value:node.code, key:id, label:node.staff_name}
				})}
                onChange={handleSelect}  
                name="author"
                 value={search.author} 
                 
                 />

</div>
       </div>

       <div className="col-md-4">
       <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Search By Status</label> 
       <Select  options={[
                    { value: 'Published', label: 'Published' },
                    { value: 'Draft', label: 'Draft'}
                                ]} 
                onChange={handleSelect}  
                name="status"
                 value={search.status} 
                 
                 />

</div>
       </div>


       <div className="col-md-4">
       <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Search By Date Posted</label> 
       <input type="date" className='form-control' value={search.date_posted} name='date_posted' onChange={handleChange} />

</div>
       </div>


       <div className="col-md-4">
       <div className="form-group"> 
       <label  className='fw-bold mb-1'>Search By Title</label> 
       <input type="text" className='form-control' value={search.title} name='title'  onChange={handleChange}  />

</div>
       </div>



       <div className="col-md-4">
       <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Search By Staff Posted</label> 
       <Select  options={
				staff&&staff.map((node:any, id:number)=>{
					return {value:node.code, key:id, label:node.staff_name}
				})}
                onChange={handleSelect}  
                name="staff"
                 value={search.staff} 
                 
                 />

</div>
       </div>


</div>
<div className="card-footer ">
<div className="pull-right">

<button type='button' onClick={handleReset} className='btn btn-warning'><i className='fa fa-refresh'></i> Reset </button>&nbsp;

  <button type='button' onClick={()=>fetchContent(0)} className='btn btn-secondary'><i className='fa fa-search'></i> Search </button>
  </div>
</div>



</div> </div> 
</div>

</div>






               <div className="row row-sm">

               <div className="col-xl-12"> 
               <div className="card"> 
               <div className="card-header  d-flex justify-content-between"> 
               <div className="card-title">All News</div>

                <a href='/news/add'   className='btn btn-primary'> <i className='fa fa-plus'></i> Add News</a>
          
                </div> 
                <div className="card-body"> 
              
                <div className="row">

<div className=" col-md-8 ">

  <div className="btn-group mb-3 ">
                   
  <div className=""> 

                    <button type='button' className='btn btn-secondary' data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" ><i className="fa fa-filter text-gray-500 fs-18"></i> Column Visibility <i className='fa fa-arrow-down'></i></button>
  
   <ul className="dropdown-menu top " role="menu" > 
   
   <li><a  className={director.isSN?"":"bg-secondary text-white"} href="#!" onClick={()=>setDirector({...director, isSN:!director.isAction})}>SN</a></li> 


   <li><a className={director.isPhoto?"":"bg-secondary text-white"} href="#!" onClick={()=>setDirector({...director, isPhoto:!director.isPhoto})}>Photo</a></li> 

   <li><a className={director.isTitle?"":"bg-secondary text-white"} href="#!" onClick={()=>setDirector({...director, isTitle:!director.isTitle})}>Title</a></li> 


   <li><a className={director.isDate?"":"bg-secondary text-white"} href="#!" onClick={()=>setDirector({...director, isDate:!director.isDate})}>Date Created</a></li> 

   <li><a className={director.isCategory?"":"bg-secondary text-white"} href="#!" onClick={()=>setDirector({...director, isCategory:!director.isCategory})}>Category</a></li> 

   <li><a className={director.isAuthor?"":"bg-secondary text-white"} href="#!" onClick={()=>setDirector({...director, isAuthor:!director.isAuthor})}>Author</a></li> 

   <li><a className={director.isView?"":"bg-secondary text-white"} href="#!" onClick={()=>setDirector({...director, isView:!director.isView})}>Total View</a></li> 

   <li><a className={director.isStatus?"":"bg-secondary text-white"} href="#!" onClick={()=>setDirector({...director, isStatus:!director.isStatus})}>Status</a></li> 

   <li> <a className={director.isAction?"":"bg-secondary text-white"} href="#!" onClick={()=>setDirector({...director, isAction:!director.isAction})}>Action</a></li> 
   <div className="dropdown-divider"></div> 
   <li><a href="#" onClick={handleResetColumn}>Reset Column</a></li> 
   </ul>
  
  
  </div>

                  </div>
                  </div>

    <div className=" col-md-4 ">

    <div className="input-group mb-3"> 
       
    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={content.filterText} className="form-control" placeholder="Type to search" />
       <span onClick={handleClear} className="input-group-text bg-default pointer" id="basic-addon1"><i className="fa fa-close"></i></span>  
       </div>

       
      

    </div>
</div>
            
               

               <div className="table-responsive">

                <table className='table'>
                  <thead>
                    <tr>
              <th>SN</th>
              {director.isPhoto?<th>Photo</th>:[]}
              {director.isTitle?<th>Title</th>:[]}
              {director.isCategory?<th>Category</th>:[]}
              {director.isView?<th>View</th>:[]}
              {director.isDate? <th>Date Created</th>:[]}
              {director.isAuthor?<th>Author</th>:[]}
              {director.isStatus?<th>Status</th>:[]}
              {director.isAction?<th className=''>Action</th>:[]}

                    </tr>
                  </thead>
<tbody> 
 

{filterContent&&filterContent.map((row:any, id:number)=>

<tr key={id}>
  <td>{id+1}</td>
  {director.isPhoto?<td>
          <img src={API_IMAGES+row.image_path} alt="news image" className="img-fluid img-news" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =API_IMAGES+'/default.jpg'}} />
                    
                    </td>:[]}
      {director.isTitle? <td>
        <a  href={SITE_URL+'/post/'+row.slug} target='_blank'>{row.title}</a>
        </td>:[]}

        {director.isCategory?    <td>{row.category_name}</td>:[]}
        {director.isView?    <td>{row.view_count}</td>:[]}
                   {director.isDate?   <td>{row.created_at}</td>:[]}
                   {director.isAuthor?    <td>{row.staff_name}</td>:[]}
                   {director.isStatus?  <td><span className={`badge  ${row.status==='Published'?'badge-secondary':'badge-primary'} me-1`}>{row.status}</span></td>:[]}
                   {director.isAction?<td>
  <div className='row' >  
               
               <a href={"/news/edit/"+row.code}  className="btn btn-info-transparent btn-sm" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit News"  ><i className='fa fa-pencil'></i> Modify</a>
&nbsp;

    <button className="btn btn-danger-transparent btn-sm" data-bs-toggle="tooltip" title="" data-bs-original-title="Delete" onClick={()=>DeleteModal(row.code)}><i className='fa fa-trash'></i> Delete</button>
           
           </div>
  </td>:[]}
</tr>)}

</tbody>
                </table>

                <Pagination />
               </div>
               
               </div> </div> 
               </div>

               </div>



    </>
  )
}

export default News