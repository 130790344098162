import { useState, ChangeEvent, FormEvent, useEffect, useCallback} from 'react'

import Select from 'react-select'
import axios from 'axios'
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import { API_IMAGES, API_URL, config } from '../components/Include';
import { usePaginated, DOTS } from '../components/usePagination';
import { Alerts, CustomLoader, ErrorModal, LoadingModal, closeModal } from '../components/notify';
import { ValidateIsOpen } from '../components/GlobalFunction';


const Advert =()=> {

  const [loading, setLoading] = useState(false);
  const [filterContent, setFilterContent] = useState([] as any);
  const [content, setContent] = useState([] as any);
let initial ={
  filterText:'',
  link:'',
  position:[] as any,	
  start_date:'',
  end_date:'',
  advert:'',
  advert_path:'',
  adverts:'',
  adverts_path:''	
}

  const [items, setItems] = useState(initial);
  const [update, setUpdate] = useState({
    code:'',
    link:'',
    position:'',	
    start_date:'',
    end_date:'',
    image_url:''
});
  const [errors, setErrors] = useState({
    advert:'',
    link:'',
    position:'',	
    start_date:'',
    end_date:'',	
    successMessage:'',
    errorMessage:''
  });


  const handleSelect = (option:any, action:any)=>{
  setItems({...items, [action.name]:option});
      setErrors({...errors, [action.name]:''})
       }

const handleReset =()=>{
  setItems(initial)
}


const handleSelectEdit = (option:any, action:any)=>{
  setUpdate({...update, [action.name]:option.value});
      setErrors({...errors, [action.name]:''})
       }
     


       const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	

        setItems({...items, [name]:value});
       setErrors({...errors, [name]:''});
     }




     const handleChangeEdit =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	

      setUpdate({...update, [name]:value});
     setErrors({...errors, [name]:''});
   }

     const handleClear =()=>{
      setItems({...items, filterText:''});
      setFilterContent(content)
  }

   const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	
      setItems({...items, [name]:value});

      if(value!==''){
          const filteredItems = content.filter(
              (item:any) => item.title.toLowerCase().includes(value.toLowerCase()) || 
              item.status.toLowerCase().includes(value.toLowerCase()) 
          );

          setFilterContent(filteredItems)
      }else{
          setFilterContent(content)
      } 

   }

 
   const handleUpdate =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!update.start_date){
    error.start_date =msg;
    formIsValid = false;
} 

if(!update.end_date){
    error.end_date =msg;
    formIsValid = false;
} 

if(!formIsValid){
setErrors(error) 
}


if(formIsValid) {

  let click = document.getElementById('closeedit')?.click();
    LoadingModal()
 const fd = new FormData();

 fd.append('code', update.code);
 fd.append('image_url', update.image_url);
 fd.append('link', update.link);
 fd.append('position', update.position);
 fd.append('start_date', update.start_date);
 fd.append('end_date', update.end_date);
 if(items.advert){
   fd.append('advert', items.adverts)
 }


   let url = API_URL+'/advert/update/'+update.code;
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){

setErrors({...errors, successMessage:response.data.message})
  

              } else{

setErrors({...errors, errorMessage:response.data.message})
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{
            closeModal()
            fetchContent()
          }) 
    }
}

     const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
      event.preventDefault();
  
      let error = {...errors}; 
      let formIsValid = true;
  
  let msg ='This field is required';
  
  if(items.position.length===0){
      error.position =msg;
      formIsValid = false;
  } 

  if(!items.start_date){
    error.start_date =msg;
    formIsValid = false;
}


if(!items.end_date){
  error.end_date =msg;
  formIsValid = false;
}

if(!items.advert){
  error.advert =msg;
  formIsValid = false;
}
  if(!formIsValid){
  setErrors(error) 
  }
  
  
  if(formIsValid) {
  
    let click = document.getElementById('close')?.click();
      LoadingModal()
   const fd = new FormData();
  
          fd.append('link', items.link);
          fd.append('position', items.position.value);
          fd.append('start_date', items.start_date);
          fd.append('end_date', items.end_date);
          if(items.advert){
            fd.append('advert', items.advert)
          }

     let url = API_URL+'/advert/create';
    axios.post(url, fd, config)
    .then(response =>{
      if(response.data.status === 200){

setErrors({...errors, successMessage:response.data.message})
    
  handleReset()

                } else{

setErrors({...errors, errorMessage:response.data.message})
                }   
            })
            .catch((error)=>{
             ErrorModal('oops', error.message)
            }).finally(()=>{
              closeModal()
              fetchContent()
            }) 
      }
  }


const fetchContent = async()=>{
  setLoading(true)
  let url = API_URL+'/advert/display/all'
    await  axios.get(url,  config).then((result:any)=>{
      if(Array.isArray(result.data.data)){
      setContent(result.data.data)
      setFilterContent(result.data.data)
      }
      })
      .catch((error)=>{
       //ErrorModal('oops', error.message)
      }).finally(()=>{
        setLoading(false)
      })
    }

   

  const getEdit =(row:any)=>{

    let click = document.getElementById('openTag')?.click();

    setUpdate(row)
  }

 

  const DeleteModal =(code:string)=>{

    let url = API_URL+'/advert/delete/'+code;
  
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url, config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       
        let rs =filterContent.filter((item:{code:string})=>item.code !==code)
            setContent(rs)
            setFilterContent(rs)
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
      }
    })
  }


  const  handleFile = function(fieldName:any){
    return function(newValue:any){                 
   readURL(newValue);
   }
  } 
  const readURL = (input:any)=>{
  
    let displayMessage = '';
    
    const PreviewImage = input.target.name;
    const inputElement = document.getElementById(PreviewImage) as HTMLInputElement
    const fileName = inputElement.value
    
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substring(idxDot, fileName.length).toLowerCase();
    
    if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 3) {
        displayMessage = ' File uploaded is larger than maximum size Allow (3 mb).';
        inputElement.value = '';
     
     setItems({...items, [PreviewImage]:'', [PreviewImage+'_path']:''})
   
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            var reader = new FileReader();//Initialize FileReader.
            reader.onload = function (e:any) {
             
            //Check if input has files.
            setItems({...items, [PreviewImage]:input.target.files[0], [PreviewImage+'_path']:e.target.result})
            setErrors({...errors, advert:''})
            }
            reader.readAsDataURL(input.target.files[0]);
        }
    }
    }else{
    displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 3 mb'
    inputElement.value = '';
    setItems({...items, [PreviewImage]:'', [PreviewImage+'_path']:''})
   
    }   
    
    if(displayMessage.length > 0){
      Alerts('danger', displayMessage)
    }
    }
  

    
  const TableData =()=>{

            
    const PaginationWrapper =(props:any)=>{
    
        const handleRowsPerPage = useCallback(
            (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
            [props.currentPage, props.onChangeRowsPerPage],
        );
        const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
        [props.currentPage, props.onChangeRowsPerPage],
        )
       
        function getNumberOfPages(rowCount:number, rowsPerPage:number) {
            return Math.ceil(rowCount / rowsPerPage);
          }
        const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
        const lastIndex = props.currentPage * props.rowsPerPage;
        const firstIndex = lastIndex - props.rowsPerPage + 1;
    
    
            const range =
            props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
    
            `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
    
        
       
        const Pagination = () => {
           
            const pageSize = props.rowsPerPage;
            const totalCount = content.length
            const siblingCount = 1
            const currentPage = props.currentPage
            
            const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
            
            if (currentPage === 0 ) {
              return null;
            }
            
            let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
            return (
            <nav className="" aria-label="Page navigation sample ">
              <ul  className="pagination pagination-sm"  >
                <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                 <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Prev</a>
                </li>
                {paginationRange&&paginationRange.map((pageNumber, index) => {
                  if (pageNumber === DOTS) {
                    return <li className="pagination-item dots" key={index}>
                     <a className="page-link pointer"  >&#8230;</a>
                    </li>
                  }
                  return (
                    <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                      <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                      
                    </li>
                  );
                })}
            
            <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
            <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                </li>
            
              </ul> </nav>
            );
            
                }
    
              
  
              
    
     return <div className="row mt-3">
                        
    <div className="col-md-5 ">
    <div className=" ml-3" >
    
        &nbsp; <select name="rowPerPage" id="" className=' h-40 ' onChange={handleRowsPerPage}>
            <option value="10"> 10</option>
            <option value="20"> 20</option>
            <option value="30"> 30</option>
            <option value="40"> 40</option>
            <option value="50"> 50</option>
            <option value={content.length}> All</option>
        </select> &nbsp;&nbsp;
         {range}  </div>
    </div>
      
    <div className="col-md-7  justify-content-end d-flex">
       <Pagination  />
        </div>
        
    </div>}
    
    
                const columns = [
    
                    {
                        name: 'SN',
                        cell: (row:any, index:number) => index+1, 
               
                        width:'60px'
                    },
                    {
                      name: 'Image',
                      cell: (row:any) => <div className="d-inline-block align-middle">
                      <img src={API_IMAGES+'/advert/'+row.image_url} alt="user image" className=" w-100 img-fluid img-thumbnail " onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =API_IMAGES+'/logo.png'}} />
                  </div>, 
                      sortable: true,
                      reorder:true,
                      width:'100px'
                  },
                    {
                      name: 'Position',
                      cell: (row:any) => row.position, 
                      sortable: true,
                      reorder:true,
                      wordwrap:true,
                  },
                  {
                    name: 'Link',
                    cell: (row:any) => row.link, 
                    sortable: true,
                    reorder:true,
                    wordwrap:true,
                    width:'200px'
                },
                    {
                        name: 'Status',
                        cell: (row:any) => <span className={`badge  ${ValidateIsOpen(row.start_date, row.end_date)===true?'badge-secondary':'badge-primary'} me-1`}>{ValidateIsOpen(row.start_date, row.end_date)===true?'Active':'Inactive'}</span>,
                      width:'100px'
                    },
                    {
                      name: 'Start Date',
                      cell: (row:any) => row.start_date, 
                      sortable: true,
                      reorder:true,
                      wordwrap:true,
                  },
                  {
                    name: 'End Date',
                    cell: (row:any) => row.end_date, 
                    sortable: true,
                    reorder:true,
                    wordwrap:true,
                },
                    {
                        name: 'Action',
                        cell: (row:any, index:any, column:any, id:any) => <div >  
                           
                            <button type='button' onClick={()=>getEdit(row)} className="btn btn-info-transparent btn-sm"   ><i className='fa fa-pencil'></i> Modify</button>
    &nbsp;
   
                 <button className="btn btn-danger-transparent btn-sm" data-bs-toggle="tooltip" title="" data-bs-original-title="Delete" onClick={()=>DeleteModal(row.code)}><i className='fa fa-trash'></i> Delete</button>
                        
                        </div>,
                            left:true,
                            width:'200px'
                    }
                ];
                
            
            
            
                return <>
                
    
                <DataTable
                columns={columns}
                data={filterContent}
                highlightOnHover
                pagination
                paginationComponent={PaginationWrapper}
                selectableRowsHighlight
                striped
                responsive
                fixedHeader
                progressPending={loading}
                progressComponent={<CustomLoader />}
            />
            </>
            }
    
            
    useEffect(()=>{  
      fetchContent()   
    },[]); 
  return (
    <>
<a href="#" id='openTag' data-bs-target="#updateAds" data-bs-toggle="modal"></a>



<div className="modal fade" id="updateAds" data-bs-backdrop="static" aria-modal="true"> 
    <div className="modal-dialog modal-dialog-centered" role="document"> 
    <div className="modal-content"> 

    <div className="modal-header  bg-gradient-dark">
              <h6 className="modal-title text-white my-auto ps-1">Update Advert</h6>
              <button type="button" id='closeedit' className="close" data-bs-dismiss="modal" aria-label="Close">
              <i className='fa fa-ban text-danger'></i>
              </button>
            </div>


    <div className="modal-body"> 
    
    
    <form action="#" className='needs-validations '> 
    <div className="row"> 

    <div className="col-md-9">

<div className="mb-3">
  <label htmlFor="file"  className={`form-label fw-bold `}>Upload Advert</label> 
  <input  type="file" className={`form-control fw-bold ${errors.advert?'border-danger':''}`} onChange={handleFile('adverts')} accept="image/*" name="adverts" id="adverts" /> 

  <div className="text-danger">{errors.advert} </div>
  </div>

</div>
<div className="col-md-3">

<div className="mb-3"><br/>
<img className=" w-100" src={items.advert_path?items.advert_path:API_IMAGES+'/advert/'+update.image_url} alt="advert"  /> 


  </div>

</div>


    <div className="col-md-12">
       <div className={`form-group  form-floating mg-md-b-0-f ${errors.link?'border-danger':''}`}> 

       <input type="text" name="link" value={update.link} onChange={handleChangeEdit} className="form-control" id="link" placeholder="" /> 
       
       <label htmlFor="link" className='fw-bold'>Enter Advert Link</label> 
       </div> 
       <div className="text-danger">{errors.link} </div>
       </div> 
       

       <div className="col-md-12">
        <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Select Position</label> 

       <Select  options={[
                    { value: 'Header', label: 'Header (w_800 x h_100)' },
                    { value: 'Mid Page', label: 'Mid Page (w_500 x h_100)' },
                    { value: 'Side Box', label: 'Side Box (w_300 x h_300)' },
                    { value: 'Footer', label: 'Footer (w_500 x h_100)' },
                                ]} 
                onChange={handleSelectEdit}  
                name="position"
                 value={{value:update.position, label:update.position}} 
                 
                 />
<span className="text-danger">{errors.position} </span>
      </div>

       </div>

       <div className="col-md-12">
       <div className={`form-group  form-floating mg-md-b-0-f ${errors.start_date?'border-danger':''}`}> 

       <input type="date" name="start_date" value={update.start_date} onChange={handleChangeEdit} className="form-control" id="start_date" placeholder="" /> 
       
       <label htmlFor="start_date" className='fw-bold'>Start Date</label> 
       </div> 
       <div className="text-danger">{errors.start_date} </div>
       </div> 


       <div className="col-md-12">
       <div className={`form-group  form-floating mg-md-b-0-f ${errors.end_date?'border-danger':''}`}> 

       <input type="date" name="end_date" value={update.end_date} onChange={handleChangeEdit} className="form-control" id="end_date" placeholder="" /> 
       
       <label htmlFor="end_date" className='fw-bold'>End Date</label> 
       </div> 
       <div className="text-danger">{errors.end_date} </div>
       </div> 

        </div> 
        </form>



    <div className="modal-footer ">

<button type="button" data-bs-dismiss="modal" className="btn btn-warning bg-gradient-warning" >
      <i className="fa fa-times-circle"></i>
      Close
      </button>
<button type="button" className="btn btn-success" onClick={handleUpdate} > <i className="fa fa-save"></i> Update </button>

</div>

    </div> 
    </div> 
    </div> 
    
    </div> 



    
    <div className="modal fade" id="addAdvert" data-bs-backdrop="static" aria-modal="true"> 
    <div className="modal-dialog modal-dialog-centered" role="document"> 
    <div className="modal-content"> 

    <div className="modal-header  bg-gradient-dark">
              <h6 className="modal-title text-white my-auto ps-1">Add New Advert</h6>
              <button type="button" id='close' className="close" data-bs-dismiss="modal" aria-label="Close">
              <i className='fa fa-ban text-danger'></i>
              </button>
            </div>


    <div className="modal-body"> 
    
    
    <form action="#" className='needs-validations '> 
    <div className="row"> 

    <div className="col-md-9">

<div className="mb-3">
  <label htmlFor="file"  className={`form-label fw-bold `}>Upload Advert</label> 
  <input  type="file" className={`form-control fw-bold ${errors.advert?'border-danger':''}`} onChange={handleFile('advert')} accept="image/*" name="advert" id="advert" /> 

  <div className="text-danger">{errors.advert} </div>
  </div>

</div>
<div className="col-md-3">

<div className="mb-3"><br/>
<img className=" w-100" src={items.advert_path} alt="advert"  /> 


  </div>

</div>


    <div className="col-md-12">
       <div className={`form-group  form-floating mg-md-b-0-f ${errors.link?'border-danger':''}`}> 

       <input type="text" name="link" value={items.link} onChange={handleChange} className="form-control" id="link" placeholder="" /> 
       
       <label htmlFor="link" className='fw-bold'>Enter Advert Link</label> 
       </div> 
       <div className="text-danger">{errors.link} </div>
       </div> 
       

       <div className="col-md-12">
        <div className="form-group"> 
       <label htmlFor="floatingInput" className='fw-bold mb-1'>Select Position</label> 

       <Select  options={[
                    { value: 'Header', label: 'Header (w_800 x h_100)' },
                    { value: 'Mid Page', label: 'Mid Page (w_500 x h_100)' },
                    { value: 'Side Box', label: 'Side Box (w_300 x h_300)' },
                    { value: 'Footer', label: 'Footer (w_500 x h_100)' },
                                ]} 
                onChange={handleSelect}  
                name="position"
                 value={items.position} 
                 
                 />
<span className="text-danger">{errors.position} </span>
      </div>

       </div>

       <div className="col-md-12">
       <div className={`form-group  form-floating mg-md-b-0-f ${errors.start_date?'border-danger':''}`}> 

       <input type="date" name="start_date" value={items.start_date} onChange={handleChange} className="form-control" id="start_date" placeholder="" /> 
       
       <label htmlFor="start_date" className='fw-bold'>Start Date</label> 
       </div> 
       <div className="text-danger">{errors.start_date} </div>
       </div> 


       <div className="col-md-12">
       <div className={`form-group  form-floating mg-md-b-0-f ${errors.end_date?'border-danger':''}`}> 

       <input type="date" name="end_date" value={items.end_date} onChange={handleChange} className="form-control" id="end_date" placeholder="" /> 
       
       <label htmlFor="end_date" className='fw-bold'>End Date</label> 
       </div> 
       <div className="text-danger">{errors.end_date} </div>
       </div> 

        </div> 
        </form>


    <div className="modal-footer ">

<button type="button" data-bs-dismiss="modal" className="btn btn-warning bg-gradient-warning" >
      <i className="fa fa-times-circle"></i>
      Close
      </button>
<button type="button" className="btn btn-success" onClick={handleSubmit} > <i className="fa fa-save"></i> Save </button>

</div>

    </div> 
    </div> 
    </div> 
    
    </div> 


       


              
    <div className="page-header d-sm-flex d-block"> 
               
               <h4 className="page-title">
                <i className="fa fa-cogs me-1"></i> Advertisement</h4> 
                <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="/dashboard">Home</a></li> 
                <li className="breadcrumb-item active" aria-current="page">Advert</li> </ol> 
                </div> 


                {errors.errorMessage!==''?
				  <div className="alert alert-danger alert-dismissible fade show" role="alert">
          {errors.errorMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}
          

          {errors.successMessage!==''?
				  <div className="alert alert-success alert-dismissible fade show" role="alert">
          {errors.successMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}

               <div className="row row-sm">

               <div className="col-xl-12"> 
               <div className="card"> 
               <div className="card-header  d-flex justify-content-between"> 
               <div className="card-title">All Adverts</div>

              
          
                </div> 
                <div className="card-body"> 



                <div className="row">

<div className=" col-md-8 ">

  <div className="btn-group mb-3 ">
                   
  <button type='button'  data-bs-target="#addAdvert" data-bs-toggle="modal"  className='btn btn-primary'> <i className='fa fa-plus'></i> Add New Advert</button>


                  </div>
                  </div>

    <div className=" col-md-4 ">

    <div className="input-group mb-3"> 
       
    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={items.filterText} className="form-control" placeholder="Type to search" />
       <span onClick={handleClear} className="input-group-text bg-default pointer" id="basic-addon1"><i className="fa fa-close"></i></span>  
       </div>
    </div>
</div>


               <TableData />

               </div> </div> 
               </div>

               </div>



    </>
  )
}

export default Advert