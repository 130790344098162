import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'

import axios from 'axios'
import { ErrorModal, LoadingModal, closeModal } from '../../components/notify';
import { API_URL, config } from '../../components/Include';

const Social =(props:any)=> {
  const [content, setContent] = useState(props.content);

    const [errors, setErrors] = useState({
      facebook:'',
        email:'',
        successMessage:'',
        errorMessage:''
      });


      const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setContent({...content, [name]:value});
       setErrors({...errors, [name]:''});
     }

     
     const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
      event.preventDefault();
  
      let error = {...errors}; 
      let formIsValid = true;
  
  let msg ='This field is required';
  
  if(!content.facebook){
      error.facebook =msg;
      formIsValid = false;
  } 


  if(!formIsValid){
  setErrors(error) 
  }
  
  
  if(formIsValid) {
  
      LoadingModal()
   const fd = new FormData();
      Object.entries(content).forEach(([key, value]) => {
              fd.append(key, String(value));
          });
  

     let url = API_URL+'/general/basic/update';
    axios.post(url, fd, config)
    .then(response =>{
      if(response.data.status === 200){

setErrors({...errors, successMessage:response.data.message})
  
                } else{

setErrors({...errors, errorMessage:response.data.message})
                }   
            })
            .catch((error)=>{
             ErrorModal('oops', error.message)
            }).finally(()=>{
              closeModal()
            }) 
      }
  }



  return (
    <>

<div className="card-block p-3">


{errors.errorMessage!==''?
<div className="alert alert-danger alert-dismissible fade show" role="alert">
{errors.errorMessage}
<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>:''}


{errors.successMessage!==''?
<div className="alert alert-success alert-dismissible fade show" role="alert">
{errors.successMessage}
<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>:''}



       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className={errors.facebook?"form-control border-danger ":"form-control"} id="facebook" placeholder="" name="facebook" value={content.facebook}  onChange={handleChange} /> 
       
       <label htmlFor="facebook" className='fw-bold'>Facebook</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Enter facebook page link.</h6>
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className="form-control" id="whatsapp" placeholder="" name="whatsapp" value={content.whatsapp}  onChange={handleChange} /> 
       
       <label htmlFor="whatsapp" className='fw-bold'>Whatsapp</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Enter whatsapp channel link or personal whatsapp page link.</h6>
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className="form-control" id="twitter" placeholder="" name="twitter" value={content.twitter}  onChange={handleChange} /> 
       
       <label htmlFor="twitter" className='fw-bold'>Twitter</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Enter official twitter page link.</h6>
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className="form-control" id="instagram" placeholder="" name="instagram" value={content.instagram}  onChange={handleChange} /> 
       
       <label htmlFor="instagram" className='fw-bold'>Instagram</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Enter official instagram page link.</h6>
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className="form-control" id="youtube" placeholder="" name="youtube" value={content.youtube}  onChange={handleChange} /> 
       
       <label htmlFor="youtube" className='fw-bold'>Youtube</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Enter official youtube page link.</h6>
       </div>

       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className="form-control" id="linkedin" placeholder="" name="linkedin" value={content.linkedin}  onChange={handleChange} /> 
       
       <label htmlFor="linkedin" className='fw-bold'>Linkedin</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Enter official linkedin page link.</h6>
       </div>



       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className="form-control" id="tiktok" placeholder="" name="tiktok" value={content.tiktok}  onChange={handleChange} /> 
       
       <label htmlFor="tiktok" className='fw-bold'>Tiktok</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Enter official tiktok page link.</h6>
       </div>

       <div className="card-footer">
<div className="mb-4">
          <button type='button'  onClick={handleSubmit} className='btn btn-success btn-block '><i className='fa fa-save'></i> Update Info</button>&nbsp;
  </div>

</div>
</div>
       </>
  )
}

export default Social