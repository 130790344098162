export   const styles = {
    container: (css:any) => ({ ...css, flex: "1 1 auto", alignSelf: "stretch" }),
    menu: (css: any) => ({ ...css, zIndex: 999 }),
    control: (css:any) => ({ ...css,  height:45 })
  };

  export  const longDate=(conv:string)=>{
    if(conv!==''&&conv!=='0000-00-00'){
    return new Date(conv).toLocaleDateString("en-US", {month: "long"})
    }else{
      return ''
    }
  }  

  export const getRank=(i:number)=> {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return  "st";
    }
    if (j == 2 && k != 12) {
        return  "nd";
    }
    if (j == 3 && k != 13) {
        return  "rd";
    }
    return  "th";
}


export const getTime =(value:String)=>{
  var timeString = String(value)
 var hourEnd = timeString.indexOf(":");
 var H:string | Number = Number(timeString.substring(0,2));
 var h: any | Number = Number(H) % 12 || 12;
 var ampm =  (Number(H)<12 || Number(H)===24)?"AM":"PM";
  if (h < 10) 
  h = "0" + h
 var result = h + timeString.substring(hourEnd, 3)+" "+ampm

return result;
}


  export const getBritishDate=(dateString:any)=>{
    var months = ['January', 'February', 'March', 
    'April', 'May', 'June', 'July', 
    'August', 'September', 'October', 'November', 'December'];
  
  var splitDate = dateString.split('-');
  var month = Number(splitDate[1])-1; //Javascript months are 0-11
  var surfix = getRank(splitDate[2]).toLowerCase()
  
  let newDate = parseInt(splitDate[2])+surfix+ ' '+ months[month]+ ', '+splitDate[0]
  
  return newDate
  }



  export const shortText = function(text:string, limit:number){
    if (text.length > limit){
        for (let i = limit; i > 0; i--){
            if(text.charAt(i) === ' ' && (text.charAt(i-1) !== ','||text.charAt(i-1) !== '.'||text.charAt(i-1) !== ';')) {
                return text.substring(0, i) + '...';
            }
        }
         return text.substring(0, limit) + '...';
    }
    else
        return text;
  };

  export const convertDateTime=(date:string)=>{
    let result = ''
    if(date!=='0000-00-00 00:00:00'){
   result = new Date(date).toISOString().slice(0,19)
    }else{
      result =''
    }
    return result
  }


  export const uniqueArray =(arr:any)=> {
    return arr.filter((element:any, index:number, self:any) => {
    return self.indexOf(element) === index;
    });
    }

    export const  compare=(a:any,b:any)=>{
      let result = 0;
      if(Number(a.order)>Number(b.order)){
          result = 1
      }else if (Number(a.order)<Number(b.order)){
          result = -1
      }
      return result
      }


      
export  const ValidateIsOpen =(start:any, end:any)=>{

  var begin = new Date(start);
  var expire = new Date(end);
  var current= new Date();
  
  var msStart:any = Date.UTC(begin.getFullYear(), begin.getMonth()+1, begin.getDate(), begin.getHours(), begin.getMinutes()); 
  var msExpire:any = Date.UTC(expire.getFullYear(), expire.getMonth()+1, expire.getDate(), expire.getHours(), expire.getMinutes()); 
  var msNow:any = Date.UTC(current.getFullYear(), current.getMonth()+1, current.getDate(), current.getHours(), current.getMinutes()); 
  
  if (parseFloat(msStart) <= parseFloat(msNow) && parseFloat(msExpire) >= parseFloat(msNow)) 
        return true;  // start date is less than today and expiredate is greater than today
      else if (parseFloat(msStart) === parseFloat(msExpire)) 
        return false;  // same date & time choosing 
      else if ( !parseFloat(msStart) && !parseFloat(msExpire)) 
        return false;  // gt 
      else 
        return false;
  
  }