import { useState, ChangeEvent, FormEvent, useEffect, useCallback} from 'react'

import axios from 'axios';
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import { API_URL, config } from '../components/Include';
import { usePaginated, DOTS } from '../components/usePagination';
import { CustomLoader, ErrorModal, LoadingModal, closeModal } from '../components/notify';


const Collection =()=> {

  const [loading, setLoading] = useState(false);
  const [filterContent, setFilterContent] = useState([] as any);
  const [content, setContent] = useState([] as any);
  const [items, setItems] = useState({
   collection_name:'',
   	collection_type:'',
    	is_home:'Yes',
      code:'',
      status:'Yes',
      collection_list:'[]',
      is_update:false
  })

  const [errors, setErrors] = useState({
    collection_name:'',
    collection_type:'',
    successMessage:'',
    errorMessage:''
  });
  

  const handleSwitch =(name:string, value:string)=>{
    value = value==='Yes'?'No':'Yes'
  setItems({...items, [name]:value});
}


const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
  event.preventDefault();

  let error = {...errors}; 
  let formIsValid = true;

let msg ='This field is required';

if(!items.collection_name){
  error.collection_name =msg;
  formIsValid = false;
} 

if(!items.collection_type){
  error.collection_type =msg;
  formIsValid = false;
} 


if(!items.is_update){
const newContent = content.map((item:any)=>{
if(items.collection_name===item.collection_name){
  error.collection_name=' Name already exist';
formIsValid = false;
}
})
}


if(!formIsValid){
setErrors(error) 
}


if(formIsValid) {

let click = document.getElementById('close')?.click();
  LoadingModal()
const fd = new FormData();
  Object.entries(items).forEach(([key, value]) => {
          fd.append(key, String(value));
      });


 let url = API_URL+'/collection/create';

 if(items.is_update){
  url = API_URL+'/collection/update/'+items.code;
 }
axios.post(url, fd, config)
.then(response =>{
  if(response.data.status === 200){

setErrors({...errors, successMessage:response.data.message})

window.location.reload()

            } else{

setErrors({...errors, errorMessage:response.data.message})
            }   
        })
        .catch((error)=>{
         ErrorModal('oops', error.message)
        }).finally(()=>{
          closeModal()
          fetchContent()
        }) 
  }
}


const fetchContent = async()=>{
  setLoading(true)
let url = API_URL+'/collection/display/all'
await  axios.get(url,  config).then((result:any)=>{
  if(Array.isArray(result.data.data)){
  setContent(result.data.data)
  setFilterContent(result.data.data)
  }
  })
  .catch((error)=>{
   //ErrorModal('oops', error.message)
  }).finally(()=>{
    setLoading(false)
  })
}



const getEdit =(row:any)=>{

let click = document.getElementById('openTag')?.click();

setItems({
  ...row,
  is_update:true
    })
}



const DeleteModal =(code:string)=>{

let url = API_URL+'/collection/delete/'+code;

Swal.fire({
  title: 'Are you really sure?',
  text: "This is very dangerous, you shouldn't do it!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  showLoaderOnConfirm: true,
preConfirm: async() => {
  return await axios.get(url, config).then(response=>{
   if(response.data.status!==200){
        throw new Error(response.statusText)
      }
      return response.data
    })
    .catch(error => {
      Swal.showValidationMessage(
        `Request failed: ${error}`
      )
    })
},
allowOutsideClick: () => !Swal.isLoading(),
  confirmButtonText: 'Yes, I am!'
}).then((result:any) => {
  if (result.isConfirmed && result.value.status===200) {
   
    let rs =filterContent.filter((item:{code:string})=>item.code !==code)
        setContent(rs)
        setFilterContent(rs)
     Swal.fire(
                'Deleted!',
                'Record has been deleted.',
                'success'
              ) 
  }
})
}

const handleChangeSelect =(event:ChangeEvent<HTMLSelectElement>)=>{
  let {name, value} = event.target;	

  setItems({...items, [name]:value});
 setErrors({...errors, [name]:''});
}

const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
  let {name, value} = event.target;	

  setItems({...items, [name]:value});
 setErrors({...errors, [name]:''});
}



  const TableData =()=>{

            
    const PaginationWrapper =(props:any)=>{
    
        const handleRowsPerPage = useCallback(
            (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
            [props.currentPage, props.onChangeRowsPerPage],
        );
        const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
        [props.currentPage, props.onChangeRowsPerPage],
        )
       
        function getNumberOfPages(rowCount:number, rowsPerPage:number) {
            return Math.ceil(rowCount / rowsPerPage);
          }
        const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
        const lastIndex = props.currentPage * props.rowsPerPage;
        const firstIndex = lastIndex - props.rowsPerPage + 1;
    
    
            const range =
            props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
    
            `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
    
        
       
        const Pagination = () => {
           
            const pageSize = props.rowsPerPage;
            const totalCount = content.length
            const siblingCount = 1
            const currentPage = props.currentPage
            
            const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
            
            if (currentPage === 0 ) {
              return null;
            }
            
            let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
            return (
            <nav className="" aria-label="Page navigation sample ">
              <ul  className="pagination pagination-sm"  >
                <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                 <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                </li>
                {paginationRange&&paginationRange.map((pageNumber, index) => {
                  if (pageNumber === DOTS) {
                    return <li className="pagination-item dots" key={index}>
                     <a className="page-link pointer"  >&#8230;</a>
                    </li>
                  }
                  return (
                    <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                      <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                      
                    </li>
                  );
                })}
            
            <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
            <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                </li>
            
              </ul> </nav>
            );
            
                }
    
              
    
    
                return <div className="row mt-3">
                        
                <div className="col-md-5 ">
                <div className=" ml-3" >
                
                    &nbsp; <select name="rowPerPage" id="" className=' h-40 ' onChange={handleRowsPerPage}>
                        <option value="10"> 10</option>
                        <option value="20"> 20</option>
                        <option value="30"> 30</option>
                        <option value="40"> 40</option>
                        <option value="50"> 50</option>
                        <option value={content.length}> All</option>
                    </select> &nbsp;&nbsp;
                     {range}  </div>
                </div>
                  
                <div className="col-md-7  justify-content-end d-flex">
                   <Pagination  />
                    </div>
                    
                </div>}

                const columns = [
                  {
                    name: 'SN',
                    cell: (row:any, index:number) => index+1, 
           
                    width:'60px'
                }, 
                    {
                      name: 'Name',
                      cell: (row:any) => row.collection_name, 
                      sortable: true,
                      reorder:true,
                      width:'200px'
                  },
                  {
                    name: 'Type',
                    cell: (row:any) => row.collection_type, 
                    sortable: true,
                    reorder:true,
                    width:'100px'
                },
                    {
                        name: 'Is Home',
                        selector: (row:any) =>row.is_home,
                        sortable: true,
                        reorder:true,
                        width:'150px'
                    },
                    {
                      name: 'Total',
                      selector: (row:any) =><a href={"/collection/add/"+row.code} > {'Add Item('+row.total+')' }</a>,
                      sortable: true,
                      reorder:true,
                      width:'200px'
                  },
                    {
                      name: 'Is Active',
                      cell: (row:any) => <span className={`badge  ${row.status==='Yes'?'badge-secondary':'badge-danger'} me-1`}>{row.status}</span>,
                    width:'100px'
                  },
                    
                  {
                    name: 'Action',
                    cell: (row:any, index:any, column:any, id:any) => <div >  
                       
                        <button type='button' onClick={()=>getEdit(row)} className="btn btn-info-transparent btn-sm"   ><i className='fa fa-pencil'></i> Modify</button>
          &nbsp;

             <button className="btn btn-danger-transparent btn-sm" data-bs-toggle="tooltip" title="" data-bs-original-title="Delete" onClick={()=>DeleteModal(row.code)}><i className='fa fa-trash'></i> Delete</button>
                    
                    </div>,
                        left:true,
                }
                ];
                
            
            
            
                return <>
                
    
                <DataTable
                columns={columns}
                data={filterContent}
                highlightOnHover
                pagination
                paginationComponent={PaginationWrapper}
                selectableRowsHighlight
                striped
                responsive
                fixedHeader
                progressPending={loading}
                progressComponent={<CustomLoader />}
            />
            </>
            }
    


            
            useEffect(()=>{  
              fetchContent()   
            },[]); 
  return (
    <>
    

    <div className="modal fade" id="addCollection" data-bs-backdrop="static" aria-modal="true"> 
    <div className="modal-dialog modal-dialog-centered" role="document"> 
    <div className="modal-content"> 

    <div className="modal-header  bg-gradient-dark">
              <h6 className="modal-title text-white my-auto ps-1">{items.is_update?'Update Collection':'Create New Collection'}</h6>
              <button type="button" className="close" data-bs-dismiss="modal" id='close' aria-label="Close">
              <i className='fa fa-ban text-danger'></i>
              </button>
            </div>


    <div className="modal-body"> 
    
    
    <form action="#"> 
    <div className="row"> 
    <div className="col-md-12">
       <div className={`form-group  form-floating mg-md-b-0-f ${errors.collection_name?'border-danger':''}`}> 

       <input type="text" name='collection_name' value={items.collection_name} onChange={handleChange} className="form-control" id="collection_name" placeholder="" /> 
       
       <label htmlFor="collection_name" className='fw-bold'>Collection Name</label> 
       </div> 
       <div className="text-danger">{errors.collection_name} </div>
       </div> 
       

       
       <div className="col-md-12">
       <div className={`form-group  form-floating mg-md-b-0-f ${errors.collection_type?'border-danger':''}`}> 

       <select  id="collection_type"  name='collection_type' value={items.collection_type} onChange={handleChangeSelect} className='form-control'>
       <option value="" selected disabled>--Select --</option>
        <option value="News">News</option>
        <option value="Comments">Comments</option>
        <option value="Polls">Polls</option>
        <option value="Advert">Advert</option>
       </select>
       
       <label htmlFor="collection_type" className='fw-bold'>Collection Type</label> 
       </div>   <div className="text-danger">{errors.collection_type} </div>
       </div>
       

       <div className="col-md-12"> 

       <div className="form-group"> 
       <label className="custom-switch"> 

       <input type="radio" name="is_home" id='is_home' className="custom-switch-input" onClick={()=>handleSwitch('is_home', items.is_home)} checked={items.is_home==='Yes'?true:false}  value={items.is_home} /> 

       <span className="custom-switch-indicator custom-switch-indicator-lg custom-radius"></span> 

       <span className="fs-14  fw-bold me-2"> Appear on Homepage?</span>
       </label>
        </div>

        </div> 

        

        <div className="col-md-12"> 

<div className="form-group"> 
<label className="custom-switch"> 

<input type="radio" name="status" className="custom-switch-input" onClick={()=>handleSwitch('status', items.status)} checked={items.status==='Yes'?true:false}  value={items.status} /> 

<span className="custom-switch-indicator custom-switch-indicator-lg custom-radius"></span> 

<span className="fs-14  fw-bold me-2"> Is Active?</span>
</label>
 </div>

 </div> 


        </div> 
        </form>


    <div className="modal-footer ">

<button type="button" data-bs-dismiss="modal" className="btn btn-warning bg-gradient-warning" >
      <i className="fa fa-times-circle"></i>
      Close
      </button>&nbsp;
<button type="button" onClick={handleSubmit} className="btn btn-success  " > <i className="fa fa-save"></i> Save </button>

</div>

    </div> 
    </div> 
    </div> 
    
    </div> 



              
    <div className="page-header d-sm-flex d-block"> 
               
               <h4 className="page-title">
                <i className="fa fa-comments me-1"></i> Collections</h4> 
                <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="#">Home</a></li> 
                <li className="breadcrumb-item active" aria-current="page">Collection</li> </ol> 
                </div> 



                {errors.errorMessage!==''?
				  <div className="alert alert-danger alert-dismissible fade show" role="alert">
          {errors.errorMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}
          

          {errors.successMessage!==''?
				  <div className="alert alert-success alert-dismissible fade show" role="alert">
          {errors.successMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}

               <div className="row row-sm">

               <div className="col-xl-12"> 
               <div className="card"> 
               <div className="card-header  d-flex justify-content-between"> 
               <div className="card-title">All Collections</div>

                <button type='button'  data-bs-target="#addCollection" id='openTag' data-bs-toggle="modal"  className='btn btn-primary'> <i className='fa fa-plus'></i> Add Collection</button>
          
                </div> 
                <div className="card-body"> 
              
               <TableData />

                </div> </div> 
               </div>

               </div>



    </>
  )
}

export default Collection