import React, { useEffect, useState } from 'react'
import { API_IMAGES, API_URL, StaffCode, Token, config } from './Include'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
const Topbar =()=> {

  const dispatch = useDispatch()
  const [user, setUser] = useState([]as any);

  const fetchContent = async()=>{
      if(Token !== undefined){
      let url = API_URL+'/staff/profile/'+StaffCode
        await  axios.get(url,  config).then((result:any)=>{
          
          if(result.data.length!==0){

              dispatch({
                  type:'SET_RECORDS',
                  name:'user',
                  data:result.data.data
                })

              setUser(result.data.data)
          }
          })
          .catch((error)=>{
              window.location.href='/logout'
          })
        
          }else{
          window.location.href='/logout'
         }
      }


useEffect(()=>{
  fetchContent()
}, [])

  return (
 
    <div className="app-header sticky"> 



<div className="main-container d-flex container-fluid"> 

<div className="d-flex header-left"> 
<div className="responsive-logo"> 
    <a className="main-logo" href="/dashboard">
       <img src="/images/longwhite.jpg" className="desktop-logo desktop-logo-dark" alt="logo" /> 
       <img src="/images/logo.jpg" className="desktop-logo" alt="logo" /> </a> 
    
    
    </div> 

 <div className="header-nav-link"> <a href="#" data-bs-toggle="sidebar" className="nav-link icon toggle app-sidebar__toggle"><i className="ri-align-left header-icon"></i></a> </div> 
 
 <div className="header-nav-link dropdown d-none d-lg-flex"> <a href="#" className="d-flex nav-link icon br-0" data-bs-toggle="dropdown" aria-expanded="false"> <i className="ri-search-line"></i> </a> <div className="dropdown-menu header-search dropdown-menu-start dropdown-menu-arrow" > 
 <div className="input-group w-300 px-2 py-1 "> 
 <input type="text" className="form-control " placeholder="Search...." /> <div className="input-group-append "> <button type="button" className="btn btn-primary "> <i className="ri-search-line header-icon" aria-hidden="true"></i> </button> </div>
 
  </div> </div> 
  </div> </div> 
  
  <div className="d-flex header-right ms-auto"> <div className="header-nav-link"> <a href="#" className="nav-link icon d-lg-none" role="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4"> <i className="ri-more-2-fill"></i> </a> </div> 
 
 <div className="responsive-navbar align-items-stretch navbar-expand-lg navbar-dark p-0 mb-0"> 
 <div className="collapse align-items-stretch navbar-collapse d-flex" id="navbarSupportedContent-4"> <ul className="nav"> 
 
 
  <li className="dropdown header-nav-link d-flex d-lg-none"> <a href="#" className="d-flex nav-link icon br-0" data-bs-toggle="dropdown"> <i className="ri-search-line"></i> </a> 
  
  <div className="dropdown-menu header-search dropdown-menu-arrow"> <div className="input-group px-2 py-1 ">
     <input type="text" className="form-control " placeholder="Search...." /> <div className="input-group-append "> 
     <button type="button" className="btn btn-primary "> <i className="ri-search-line header-icon" aria-hidden="true"></i> </button> </div> </div> </div> 
  
  </li> 
  
   <li className="header-nav-link"> 
   <a href="#" className="nav-link icon layout-setting light-layout"> <i className="ri-moon-line header-icon"></i> </a>
   
    <a href="#" className="nav-link icon layout-setting dark-layout"> <i className="ri-moon-line header-icon"></i> </a> 
    </li> 
   
   
    <li className="dropdown header-nav-link"> <a href="#" className="nav-link icon" data-bs-toggle="dropdown"> <i className="ri-mail-line header-icon"></i> <span className="badge badge-warning ">4</span> </a> 
    
    
    <div className="dropdown-menu Messages-dropdown dropdown-menu-arrow p-0">
      
    
    </div> </li> 
    


    <li className="dropdown header-nav-link"> 
    <a href="#" className="nav-link icon" data-bs-toggle="dropdown"> <i className="ri-notification-2-line header-icon"></i> 
    <span className="badge badge-danger pulse-danger">3</span> 
    </a> 
    
    
     <div className="dropdown-menu Notifications-dropdown dropdown-menu-end dropdown-menu-arrow p-0"> 
    
    
     </div>  
    
    </li> 
    
    
    
     
      


       <div className="dropdown header-nav-link"> 

       <a className="nav-link icon leading-none d-flex" data-bs-toggle="dropdown" href="#"> 
       <img className="avatar avatar-md brround cover-image" src={API_IMAGES+'/staff/'+user.image_url} alt="profile"  /> </a> 
       
       <div className="dropdown-menu dropdown-menu-arrow p-0"> 
       
       
       <div className="drop-heading py-3 ps-4 pe-4">
         <div className="text-center"> <h5 className="text-dark mb-1">{user.staff_name}</h5> 
         <small className="text-muted">{user.group_code}</small> </div> 
         </div> 
         
         <div className="dropdown-divider m-0"></div> 
         
         <a className="dropdown-item" href="#"><i className="dropdown-icon fa fa-user"></i>Profile</a> 

         <a className="dropdown-item" href="todotask.html"><i className="dropdown-icon fa fa-edit"></i>Your Draft</a> 
         <a className="dropdown-item" href="#"><i className="dropdown-icon fa fa-cogs"></i> settings</a>

          
          <div className="dropdown-divider m-0"></div> 

          <a className="dropdown-item" href="/logout"><i className="dropdown-icon fa fa-power-off"></i> Log Out</a> 
         </div> 
           </div>
        
        
         </ul> 
       
       </div> </div>  
       
       </div> 
       </div>





           
           </div>
  )
}

export default Topbar