import React, { useCallback, useEffect, useState, ChangeEvent, FormEvent , useMemo} from 'react'

import axios from 'axios'
import { ErrorModal, LoadingModal, closeModal } from '../../components/notify';
import { API_URL, config } from '../../components/Include';

const Setup =(props:any)=> {
    const [content, setContent] = useState(props.content);

    const [errors, setErrors] = useState({
        site_name:'',
        email:'',
        successMessage:'',
        errorMessage:''
      });


      const handleChangeArea =(event:ChangeEvent<HTMLTextAreaElement>)=>{
        let {name, value} = event.target;	
        setContent({...content, [name]:value});
       setErrors({...errors, [name]:''});
     }

      const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        setContent({...content, [name]:value});
       setErrors({...errors, [name]:''});
     }

     

     const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
      event.preventDefault();
  
      let error = {...errors}; 
      let formIsValid = true;
  
  let msg ='This field is required';
  
  if(!content.site_name){
      error.site_name =msg;
      formIsValid = false;
  } 


  if(!formIsValid){
  setErrors(error) 
  }
  
  
  if(formIsValid) {
  
      LoadingModal()
   const fd = new FormData();
      Object.entries(content).forEach(([key, value]) => {
              fd.append(key, String(value));
          });
  

     let url = API_URL+'/general/basic/update';
    axios.post(url, fd, config)
    .then(response =>{
      if(response.data.status === 200){

setErrors({...errors, successMessage:response.data.message})
    //reload here
    setTimeout(() => {
      window.location.reload()
    }, 3000);

                } else{

setErrors({...errors, errorMessage:response.data.message})
                }   
            })
            .catch((error)=>{
             ErrorModal('oops', error.message)
            }).finally(()=>{
              closeModal()
            }) 
      }
  }



  return (
    <>

<div className="card-block p-3">


{errors.errorMessage!==''?
<div className="alert alert-danger alert-dismissible fade show" role="alert">
{errors.errorMessage}
<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>:''}


{errors.successMessage!==''?
<div className="alert alert-success alert-dismissible fade show" role="alert">
{errors.successMessage}
<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>:''}

       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className={errors.site_name?"form-control border-danger ":"form-control"} id="site_name" placeholder="" name="site_name" value={content.site_name}  onChange={handleChange} /> 
       
       <label htmlFor="site_name" className='fw-bold'>Site Name</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Title you want to appear on top of the website. Usually website name.</h6>
       </div>
       

       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

<textarea name="description" value={content.description} id="description" placeholder="" cols={30} rows={10} className='form-control' onChange={handleChangeArea}>
{content.description}
</textarea>
 
       
       <label htmlFor="description" className='fw-bold'>Site Description</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Meta description to be added to this page header.</h6>
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

<textarea name="keywords" id="keywords" value={content.keywords} placeholder="" cols={30} rows={10} className='form-control' onChange={handleChangeArea}>
{content.keywords}
</textarea>
 
       
       <label htmlFor="keywords" className='fw-bold'>Meta Keywords</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Meta keywords to be added to the website header. Use comma to seperate keywords.</h6>
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className="form-control" id="address" placeholder="" name="address" value={content.address}  onChange={handleChange} /> 
       
       <label htmlFor="address" className='fw-bold'>Office Address</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Office Address you want to appear on the website.</h6>
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className="form-control" id="telephone" placeholder="" name="telephone" value={content.telephone}  onChange={handleChange} /> 
       
       <label htmlFor="telephone" className='fw-bold'>Telephone</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Official Telephone Number.</h6>
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" className={errors.email?"form-control border-danger ":"form-control"} id="email" placeholder="" name="email" value={content.email}  onChange={handleChange} /> 
       
       <label htmlFor="email" className='fw-bold'>Email Address</label> 
       </div> 
       <h6 className="card-subtitle  mb-3 ">Official Email Address.</h6>
       </div>


       <div className="card-footer">
<div className="mb-4">
          <button type='button' onClick={handleSubmit}  className='btn btn-success btn-block '><i className='fa fa-save'></i> Update Info</button>&nbsp;
  </div>

</div>
</div>
       </>
  )
}

export default Setup