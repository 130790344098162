import { useState, ChangeEvent, FormEvent, useEffect, useCallback} from 'react'

import DataTable from 'react-data-table-component';
import { usePaginated, DOTS } from '../components/usePagination';
import axios from 'axios';
import { API_URL, config, SITE_URL } from '../components/Include';
import { CustomLoader, ErrorModal, LoadingModal, closeModal } from '../components/notify';
import { useParams } from 'react-router';
import { uniqueArray } from '../components/GlobalFunction';


const AddCollection =()=> {
let params =  useParams();

const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({
    collection_name:'',
    collection_type:'',
     is_home:'Yes',
     status:'Yes',
     filterText:''
  });

  const [news, setNews] = useState([] as any);
  const [filterContent, setFilterContent] = useState([] as any);
  const [newsList, setNewsList] = useState([] as any);
  const [content, setContent] = useState([] as any);
const [errors, setErrors] = useState({

  collection_name:'',	
  successMessage:'',
  errorMessage:''
});

    

const handleClear =()=>{
  setItems({...items, filterText:''});
  setFilterContent(news)
}
const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
  let {name, value} = event.target;	
  setItems({...items, [name]:value});

  if(value!==''){
      const filteredItems = news.filter(
          (item:any) => item.title.toLowerCase().includes(value.toLowerCase())
      );

      setFilterContent(filteredItems)
  }else{
      setFilterContent(news)
  } 

}

const DeleteModal =(code:string)=>{
  let newLists = content.filter((item:any)=>item!==code);
  setContent(newLists)
  UpdateNewsCollection(newLists);
  //SuccessModal('Item successfully removed')
  }



     const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	

      setItems({...items, [name]:value});
     setErrors({...errors, [name]:''});
   }

  
   const handleSwitch =( value:string)=>{
    value = value==='Yes'?'No':'Yes'
    setItems({...items, is_home:value});
    }


   const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';

if(!items.collection_name){
    error.collection_name =msg;
    formIsValid = false;
} 

if(!formIsValid){
setErrors(error) 
}



if(formIsValid) {

  
    LoadingModal()
 const fd = new FormData();
    Object.entries(items).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

      
   let url = API_URL+'/collection/update/'+params.code;
  axios.post(url, fd, config)
  .then(response =>{
    if(response.data.status === 200){

setErrors({...errors, successMessage:response.data.message})
  

              } else{

setErrors({...errors, errorMessage:response.data.message})
              }   
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{
            closeModal()
          }) 
    }
}


const fetchContent = async()=>{
  let url = API_URL+'/collection/show/'+params.code
    await  axios.get(url,  config).then((result:any)=>{

      if(result.data.length!==0){
        let list = JSON.parse(result.data.data.collection_list)
        setContent(list)
        setItems({
          ...result.data.data})
         
      }
      

      })
      .catch((error)=>{
       //ErrorModal('oops', error.message)
      })
    }



    const fetchNews = async()=>{
      let url = API_URL+'/news/all'
        await  axios.get(url,  config).then((result:any)=>{
          if(Array.isArray(result.data.data)){
          setNews(result.data.data)
          setFilterContent(result.data.data)
       
          }
          })
          .catch((error)=>{
            //ErrorModal('oops', error.message)
          })
        }



    const NewsTable =()=>{

      const handleCheck =(code:string)=>{
        const newContent = filterContent.map((item:any)=>{
            
        if(item.code === code){
        return {...item, 
            is_check:item.is_check==='false'?'true':'false',
        }
        };
        return item;
        })
        setFilterContent(newContent)
        }

const Checkbox =({code, is_check}:{code:string, is_check:string})=>{ 

return <label className="custom-control custom-checkbox custom-control-md"> <input type="checkbox" className="custom-control-input"name={code} id={code} checked={is_check==='true'?true:false} onChange={()=>handleCheck(code)}/> <span className="custom-control-label custom-control-label-md  mb-3"></span> </label>
}  
            
      const PaginationWrapper =(props:any)=>{
      
          const handleRowsPerPage = useCallback(
              (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
              [props.currentPage, props.onChangeRowsPerPage],
          );
        
         
          function getNumberOfPages(rowCount:number, rowsPerPage:number) {
              return Math.ceil(rowCount / rowsPerPage);
            }
          const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
          const lastIndex = props.currentPage * props.rowsPerPage;
          const firstIndex = lastIndex - props.rowsPerPage + 1;
      
      
              const range =
              props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
      
              `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
      
          
         
          const Pagination = () => {
             
              const pageSize = props.rowsPerPage;
              const totalCount = filterContent.length
              const siblingCount = 1
              const currentPage = props.currentPage
              
              const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
              
              if (currentPage === 0 ) {
                return null;
              }
              
              let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
              return (
              <nav className="" aria-label="Page navigation sample ">
                <ul  className="pagination pagination-sm"  >
                  <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                   <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                  </li>
                  {paginationRange&&paginationRange.map((pageNumber, index) => {
                    if (pageNumber === DOTS) {
                      return <li className="pagination-item dots" key={index}>
                       <a className="page-link pointer"  >&#8230;</a>
                      </li>
                    }
                    return (
                      <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                        <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                        
                      </li>
                    );
                  })}
              
              <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
              <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                  </li>
              
                </ul> </nav>
              );
              
                  }
      
          
      
      
                  return <div className="row mt-3">
                          
                  <div className="col-md-5 ">
                  <div className=" ml-3" >
                  
                      &nbsp; <select name="rowPerPage" id="" className=' h-40 ' onChange={handleRowsPerPage}>
                          <option value="10"> 10</option>
                          <option value="20"> 20</option>
                          <option value="30"> 30</option>
                          <option value="40"> 40</option>
                          <option value="50"> 50</option>
                          <option value={filterContent.length}> All</option>
                      </select> &nbsp;&nbsp;
                       {range}  </div>
                  </div>
                    
                  <div className="col-md-7  justify-content-end d-flex">
                     <Pagination  />
                      </div>
                      
                  </div>}
    
                  const columns = [
                    {
                      name: 'Tick',
                      cell: (row:any) => <Checkbox code={row.code} is_check={row.is_check} />, 
                      width:'80px'
                  }, 
                      {
                        name: 'Title',
                        cell: (row:any) =><a href={SITE_URL+'/news/info/'+row.slug} target='_blank'>{row.title}</a>, 
                        sortable: true,
                        reorder:true
                    },
                    {
                      name: 'Total View',
                      cell: (row:any) => row.view_count,
                      width:'200px'
                  }
                      
                  ];
                  
                  return <>
                  <DataTable
                  columns={columns}
                  data={filterContent}
                  highlightOnHover
                  pagination
                  paginationComponent={PaginationWrapper}
                  selectableRowsHighlight
                  striped
                  responsive
                  fixedHeader
                  progressPending={loading}
                  progressComponent={<CustomLoader />}
              />
              </>
              }



              const getNewsList =()=>{
                const getItemName =(code:string, field:string)=>{
                  const result = news&&news.filter((item:any)=>item.code===code)
                  const answer = result.length!==0?result[0][field]:''
                  return String(answer)
                }

                let result:any = []
              let rs =  content&&content.map((item:any, id:number)=>{
                
                result.push({
                  code:item, 
                  key:id,  
                  title:getItemName(item, 'title'),
                  status:getItemName(item, 'status'),
                  slug:getItemName(item, 'slug'),
                  view_count:getItemName(item, 'view_count')
                }
                )}
              )

              setNewsList(result)
              }




const TableData =()=>{

            
  const PaginationWrapper =(props:any)=>{
  
      const handleRowsPerPage = useCallback(
          (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
          [props.currentPage, props.onChangeRowsPerPage],
      );
    
     
      function getNumberOfPages(rowCount:number, rowsPerPage:number) {
          return Math.ceil(rowCount / rowsPerPage);
        }
      const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
      const lastIndex = props.currentPage * props.rowsPerPage;
      const firstIndex = lastIndex - props.rowsPerPage + 1;
  
  
          const range =
          props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
  
          `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
  
      
     
      const Pagination = () => {
         
          const pageSize = props.rowsPerPage;
          const totalCount = newsList.length
          const siblingCount = 1
          const currentPage = props.currentPage
          
          const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
          
          if (currentPage === 0 ) {
            return null;
          }
          
          let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
          return (
          <nav className="" aria-label="Page navigation sample ">
            <ul  className="pagination pagination-sm"  >
              <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
               <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
              </li>
              {paginationRange&&paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                  return <li className="pagination-item dots" key={index}>
                   <a className="page-link pointer"  >&#8230;</a>
                  </li>
                }
                return (
                  <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                    
                  </li>
                );
              })}
          
          <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
          <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
              </li>
          
            </ul> </nav>
          );
          
              }
  
            
  
  
              return <div className="row mt-3">
                      
              <div className="col-md-5 ">
              <div className=" ml-3" >
              
                  &nbsp; <select name="rowPerPage" id="" className=' h-40 ' onChange={handleRowsPerPage}>
                      <option value="10"> 10</option>
                      <option value="20"> 20</option>
                      <option value="30"> 30</option>
                      <option value="40"> 40</option>
                      <option value="50"> 50</option>
                      <option value={newsList.length}> All</option>
                  </select> &nbsp;&nbsp;
                   {range}  </div>
              </div>
                
              <div className="col-md-7  justify-content-end d-flex">
                 <Pagination  />
                  </div>
                  
              </div>}

              const columns = [
                {
                  name: 'SN',
                  cell: (row:any, index:number) => index+1, 
         
                  width:'60px'
              }, 
              {
                name: 'Title',
                cell: (row:any) =><a href={SITE_URL+'/news/info/'+row.slug} target='_blank'>{row.title}</a>, 
                sortable: true,
                reorder:true
            },
                {
                  name: 'Status',
                  cell: (row:any) => <span className={`badge  ${row.status==='Published'?'badge-secondary':'badge-danger'} me-1`}>{row.status}</span>,
                width:'100px'
              },
                  
                {
                  name: 'Action',
                  cell: (row:any, index:any, column:any, id:any) => <div >  
                     
                      <a href={SITE_URL+'/news/info/'+row.slug} className="btn btn-success-transparent btn-sm"   ><i className='fa fa-eye'></i> View</a>
        &nbsp;

           <button className="btn btn-danger-transparent btn-sm" data-bs-toggle="tooltip" title="" data-bs-original-title="Delete" onClick={()=>DeleteModal(row.code)}><i className='fa fa-trash'></i> Remove</button>
                  
                  </div>,
                      left:true,
                      width:'200px'
              }
              ];
              
          
          
          
              return <>
              
  
              <DataTable
              columns={columns}
              data={newsList}
              highlightOnHover
              pagination
              paginationComponent={PaginationWrapper}
              selectableRowsHighlight
              striped
              responsive
              fixedHeader
              progressPending={loading}
              progressComponent={<CustomLoader />}
          />
          </>
          }



    const handleSelectNews =()=>{
      let selectedItem = []
			const newContent = [...filterContent] 
				for (var i in newContent){
					if(newContent[i].is_check==='true'){
						selectedItem.push(newContent[i].code)
					}
				}
        const currentList = uniqueArray(content.concat(selectedItem))

        UpdateNewsCollection(currentList)
        setContent(currentList)

   
  let click = document.getElementById('close')?.click();
      
    }
    const UpdateNewsCollection =async(currentList:any)=>{
      LoadingModal()
      let url = API_URL+'/collection/change_field'
    const fd = new FormData();
      fd.append('newValue', JSON.stringify(currentList, null, 2))
        fd.append('code', String(params.code))
        fd.append('column', 'collection_list')
        fd.append('total', currentList.length)
      await  axios.post(url, fd, config).then((response:any)=>{
        if(response.data.status === 200){
          
          setErrors({...errors, successMessage:response.data.message})
      }
        })
        .catch((error)=>{
          //ErrorModal('oops', error.message)
        }).finally(()=>{
          closeModal()
        })

    }



useEffect(()=>{
  getNewsList();
}, [content, news])


  useEffect(()=>{  
    
    fetchContent()   
    fetchNews()
  },[]); 

  return (
    <>
   
    <div className="page-header d-sm-flex d-block"> 
               
               <h4 className="page-title">
                <i className="fa fa-users me-1"></i> {items.collection_name}</h4> 
                <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="/">Home</a></li> 
                <li className="breadcrumb-item"><a href="/collections">Collection</a></li>
                <li className="breadcrumb-item active" aria-current="page">Update</li> </ol> 
                </div> 




                {errors.errorMessage!==''?
				  <div className="alert alert-danger alert-dismissible fade show" role="alert">
          {errors.errorMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}
          

          {errors.successMessage!==''?
				  <div className="alert alert-success alert-dismissible fade show" role="alert">
          {errors.successMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}


               <div className="row row-sm">

               <div className="col-xl-12"> 
               <div className="card"> 
               <div className="card-header  "> 
               <div className="card-title text-primary">Add To Collection</div>

<div className="pull-right">
          <button type='button' onClick={handleSubmit} className='btn btn-secondary btn-sm'><i className='fa fa-arrows-h'></i> Save & Return</button>&nbsp;
          <a href='/collections' className='btn btn-gray-dark btn-sm'> <i className='fa fa-arrow-left'></i> Return</a>
  </div>
                </div> 
                <div className="card-body"> 


                <div className="card"> 
                <div className="card-body"> 

      <div className="row">

      <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" name="collection_name" value={items.collection_name} onChange={handleChange} placeholder="" className="form-control" id="collection_name"  /> 
       
       <label htmlFor="collection_name" className='fw-bold'>Collection Name<span className='text-danger'>*</span> </label> 
       <span className="text-danger">{errors.collection_name} </span>
       </div> 
       </div>


       <div className="col-md-12">
       <div className="form-group form-floating mg-md-b-0-f"> 

       <input type="text" name="collection_type" value={items.collection_type} disabled onChange={handleChange} placeholder="" className="form-control" id="collection_type"  /> 
       
       <label htmlFor="collection_type" className='fw-bold'>Collection Type<span className='text-danger'>*</span> </label> 
   
       </div> 
       </div>


       <div className="col-md-12"> 

<div className="form-group"> 
<label className="custom-switch"> 

<input type="radio" name="is_home" id='is_home' className="custom-switch-input" onClick={()=>handleSwitch( items.is_home)} checked={items.is_home==='Yes'?true:false}  value={items.is_home} /> 

<span className="custom-switch-indicator custom-switch-indicator-lg custom-radius"></span> 

<span className="fs-14  fw-bold me-2"> Appear on Homepage?</span>
</label>
 </div>

 </div> 
      </div>

                </div>
                </div>

<div className="card">

<div className="card-header  d-flex justify-content-between"> 
               <div className="card-title">All Items</div>

                <button type='button'  data-bs-target="#addNews" id='openTag' data-bs-toggle="modal"  className='btn btn-primary'> <i className='fa fa-plus'></i> Add News</button>
          
                </div> 
              
                <div className="card-body"> 
              
              <TableData />

               </div>
               </div>


               
               
               </div> </div> 
               </div>

               </div>



               <div className="modal fade" id="addNews" data-bs-backdrop="static" aria-modal="true"> 
    <div className="modal-dialog modal-xl " role="document"> 
    <div className="modal-content "> 

    <div className="modal-header  bg-gradient-dark">
              <h6 className="modal-title text-white my-auto ps-1">Add Item</h6>
              <button type="button" id='close' className="close" data-bs-dismiss="modal" aria-label="Close">
              <i className='fa fa-ban text-danger'></i>
              </button>
            </div>


    <div className="modal-body"> 
    
        <div className="row">

          <div className="col-md-12">


<div className="cards">


<div className="row">
<div className=" col-md-12 ">

<div className="input-group mb-3"> 
   
<input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={items.filterText} className="form-control" placeholder="Type to search" />
   <span onClick={handleClear} className="input-group-text bg-default pointer" id="basic-addon1"><i className="fa fa-close"></i></span>  
   </div>
</div>

</div>
              
              <NewsTable />


</div>
          </div>
        </div>


    <div className="modal-footer ">

<button type="button" data-bs-dismiss="modal" className="btn btn-warning bg-gradient-warning" >
      <i className="fa fa-times-circle"></i>
      Close
      </button>
<button type="button" onClick={handleSelectNews} className="btn btn-dark"  > <i className="fa fa-save"></i> Select </button>

</div>

    </div> 
    </div> 
    </div> 
    
    </div> 


    </>
  )
}

export default AddCollection